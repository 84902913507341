import * as types from "./actionTypes";
import axios from "axios";
import { environmentDetails } from "../../environment/environment";
import { privateAxiosInstance } from "../../utils/axiosInstance";
import { accessTokenHeadersObject } from "../../utils/headers";
const apiUrl = environmentDetails.apiUrl;

const getQuestionPackage =
  (page, rowPerPage, searchQuery = "") =>
  (dispatch) => {
    dispatch({ type: types.GET_QUESTIONPACKAGE_REQUEST });
    return privateAxiosInstance
      .get(
        `/questionnaire/get-opening?page=${page}&pageSize=${rowPerPage}&opening=${searchQuery}&processName=Initiation_preScreeningProcess`,
        { headers: { ...accessTokenHeadersObject() } }
      )
      .then((res) => {
        return dispatch({
          type: types.GET_QUESTIONPACKAGE_SUCCESS,
          payload: res.data.data,
          dataLength: res.data.dataLength,
        });
      })
      .catch((err) => {
        //console.log("err",err)
        return dispatch({
          type: types.GET_QUESTIONPACKAGE_FAILURE,
          payload: err,
        });
      });
  };

const editQuestionPackageStatus = (id, status) => (dispatch) => {
  dispatch({ type: types.EDIT_QUESTIONPACKAGESTATUS_REQUEST });
  return privateAxiosInstance
    .put(
      `questionnaire/update-openingstatus?openingId=${id}&status=${status}&processName=Initiation_preScreeningProcess`,
      {},
      { headers: { ...accessTokenHeadersObject() } }
    )
    .then((res) => {
      // console.log("res1",res)
      return dispatch({
        type: types.EDIT_QUESTIONPACKAGESTATUS_SUCCESS,
        payload: res,
      });
    })
    .catch((err) => {
      // console.log("err",err)
      return dispatch({
        type: types.EDIT_QUESTIONPACKAGESTATUS_FAILURE,
        payload: err,
      });
    });
};

const deleteQuestionPackage = (id) => (dispatch) => {
  dispatch({ type: types.DELETE_QUESTIONPACKAGE_REQUEST });
  return privateAxiosInstance
    .delete(`/questionnaire/delete-opening/${id}?processName=Initiation_preScreeningProcess`, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      //console.log("ReducerDelete",res)
      return dispatch({
        type: types.DELETE_QUESTIONPACKAGE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: types.DELETE_QUESTIONPACKAGE_FAILURE,
        payload: err,
      });
    });
};

// OPENINGS ACTIONS FUNCTIOS
const getOpeningByFilterActionFn = (jobRoleId, companiesId) => (dispatch) => {
  dispatch({ type: types.GET_OPENING_BY_FILTER_REQUEST });
  return privateAxiosInstance
    .get(
      `/questionnaire/get-opening?jId=${jobRoleId}&companyIds=${companiesId}&processName=Initiation_preScreeningProcess`,
      { headers: { ...accessTokenHeadersObject() } }
    )
    .then((res) => {
      return dispatch({
        type: types.GET_OPENING_BY_FILTER_SUCCESS,
        payload: res.data.data[0],
      });
    })
    .catch((err) => {
      return dispatch({ type: types.GET_OPENING_BY_FILTER_FAILURE });
    });
};

const getOpeningByCompanyActionFn = (companyID) => (dispatch) => {
  dispatch({ type: types.GET_OPENING_BY_FILTER_REQUEST });
  return privateAxiosInstance
    .get(
      `/questionnaire/get-openingByCompany/${companyID}?processName=Initiation_preScreeningProcess`,
      { headers: { ...accessTokenHeadersObject() } }
    )
    .then((res) => {
      if(res.data){
        return dispatch({
          type: types.GET_OPENING_BY_FILTER_SUCCESS,
          payload: res.data.data[0],
        });
      }
      else{
        return dispatch({ type: types.GET_OPENING_BY_FILTER_FAILURE });
      }
    })
    .catch((err) => {
      return dispatch({ type: types.GET_OPENING_BY_FILTER_FAILURE });
    });
};

const createNewOpeningActionFn = (openingId, openingData) => (dispatch) => {
  let selectedData = openingData.selectedQuestionList;
  // let selectedData = {...openingData};
  // delete selectedData.companyIds
  // delete selectedData.jobRoleId;
  //console.log("create new openi------> ",selectedData);
  //return
  dispatch({ type: types.CREATE_NEW_OPENING_REQUEST });
  if (openingId == "null") {
    return privateAxiosInstance
      .post(`/questionnaire/save-opening?processName=Initiation_preScreeningProcess`, openingData, {
        headers: { ...accessTokenHeadersObject() },
      })
      .then((res) => {
        return dispatch({
          type: types.CREATE_NEW_OPENING_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        return dispatch({ type: types.CREATE_NEW_OPENING_FAILURE });
      });
  } else {
    return privateAxiosInstance
      .post(`/questionnaire/save-opening/${openingId}?processName=Initiation_preScreeningProcess`, selectedData, {
        headers: { ...accessTokenHeadersObject() },
      })
      .then((res) => {
        return dispatch({
          type: types.CREATE_NEW_OPENING_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        return dispatch({ type: types.CREATE_NEW_OPENING_FAILURE });
      });
  }
};

const editOpeningQuestionActionFn = (args) => (dispatch) => {
  const { openingId, data } = args;
  dispatch({ type: types.EDIT_OPENING_QUESTION_REQUEST });
  return privateAxiosInstance
    .put(`/questionnaire/update-queOpening/${openingId}/${data.qId}?processName=Initiation_preScreeningProcess`, data, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.EDIT_OPENING_QUESTION_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.EDIT_OPENING_QUESTION_FAILURE });
    });
};

const editQuestionByOpeningActionFn = (args) => (dispatch) => {
  const { openingId, data } = args;
  dispatch({ type: types.EDIT_OPENING_QUESTION_REQUEST });
  return privateAxiosInstance
    .put(`/questionnaire/update-opening/${openingId}?processName=Initiation_preScreeningProcess`, data, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.EDIT_OPENING_QUESTION_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.EDIT_OPENING_QUESTION_FAILURE });
    });
};

// deleteOpeningOpeningQuestion
const deleteOpeningQuestionActionFn = (args) => (dispatch) => {
  const { openingId, idToDelete } = args;
  dispatch({ type: types.DELETE_OPENING_QUESTION_REQUEST });
  return privateAxiosInstance
    .delete(`/questionnaire/delete-ques/${openingId}/${idToDelete}?processName=Initiation_preScreeningProcess`, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.DELETE_OPENING_QUESTION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.DELETE_OPENING_QUESTION_FAILURE });
    });
};
// shareJobInCareer
const shareJobInCareerActionFn = (data) => (dispatch) => {
  dispatch({ type: types.SHARE_JOB_IN_CAREER_REQUEST });
  return privateAxiosInstance
    .patch(`/questionnaire/updateOpeningForCareerPortal?processName=Initiation_preScreeningProcess`, 
      data,
      {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.SHARE_JOB_IN_CAREER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.SHARE_JOB_IN_CAREER_FAILURE });
    });
};
export {
  getQuestionPackage,
  editQuestionPackageStatus,
  deleteQuestionPackage,
  getOpeningByFilterActionFn,
  createNewOpeningActionFn,
  editOpeningQuestionActionFn,
  editQuestionByOpeningActionFn,
  deleteOpeningQuestionActionFn,
  getOpeningByCompanyActionFn,
  shareJobInCareerActionFn
};
