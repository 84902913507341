import * as types from "./actionTypes";
import axios from "axios";
import { environmentDetails } from "../../../src/environment/environment";
import { accessTokenHeadersObject } from "../../utils/headers";
import { privateAxiosInstance } from "../../utils/axiosInstance";
const apiUrl = environmentDetails.apiUrl;

// get company action fn
const getRecruitmentCompanyActionFn =
  (page = 1, pageSize = 10, companyName = "",companyStatus) =>
  (dispatch) => {
    dispatch({ type: types.GET_RECRUITMENT_COMPANY_REQUEST });
    return privateAxiosInstance
      .get(
        `/questionnaire/get-cCompany?page=${page}&pageSize=${pageSize}&companyName=${companyName}&companyStatus=${companyStatus}&processName=Initiation_preScreeningProcess`,
        { headers: { ...accessTokenHeadersObject() } }
      )
      .then((res) => {
        // console.log("length",res.data.dataLength)
        return dispatch({
          type: types.GET_RECRUITMENT_COMPANY_SUCCESS,
          payload: res.data.data,
          dataLength: res.data.dataLength,
        });
      })
      .catch((err) => {
        return dispatch({
          type: types.GET_RECRUITMENT_COMPANY_FAILURE,
          payload: err,
        });
      });
  };

  const getRecruitmentCompanyByEmail =
  (email) =>
  (dispatch) => {
    dispatch({ type: types.GET_RECRUITMENT_COMPANY_REQUEST });
    return privateAxiosInstance
      .post(
        `/questionnaire/get-childcompany?processName=Initiation_preScreeningProcess`,
        {email},
        { headers: { ...accessTokenHeadersObject() } }
      )
      .then((res) => {
        if(res.data != undefined){
          return dispatch({
            type: types.GET_RECRUITMENT_COMPANY_SUCCESS,
            payload: res.data,
            dataLength: res.data.length,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: types.GET_RECRUITMENT_COMPANY_FAILURE,
          payload: err,
        });
      });
  };

//GET IFLOWCHILDCOMPANYUSINGPARENTID
const getIflowChildCompanyUsingParentId = (masterCompanyId) => (dispatch) => {
  dispatch({ type: types.GET_IFLOW_RECRUITMENT_COMPANY_REQUEST });
  return privateAxiosInstance
    .get(`questionnaire/iflowchildcompany/${masterCompanyId}?processName=Initiation_preScreeningProcess`, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      // console.log("Childres",res.data.data)
      /* let iflowChildData = []
      let iflowData = res.data.data
      iflowData.forEach(elem => {
        // console.log(elem)
        if(elem.id != '3513'){
          iflowChildData.push(elem)
        }
      }); */
      return dispatch({
        type: types.GET_IFLOW_RECRUITMENT_COMPANY_SUCCESS,
        ilfowChildCompanyData: res?.data?.data,
        // ilfowChildCompanyData: iflowChildData,
      });
    })
    .catch((err) => {
      return dispatch({
        type: types.GET_IFLOW_RECRUITMENT_COMPANY_FAILURE,
        ilfowChildCompanyData: err,
      });
    });
};

const postRecruitmentCompanyInFlow = (data) => (dispatch) => {
  dispatch({ type: types.POST_IFLOWRECRUITMENT_COMPANY_REQUEST });

  console.log('++++', data)
  
  try{
    return privateAxiosInstance
    .post(`questionnaire/updateIflowCompanyByID?processName=Initiation_preScreeningProcess`, data, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      // console.log("postres", res);
      if (res.status === 200) {
        return dispatch({
          type: types.POST_IFLOWRECRUITMENTCOMPANY_SUCCESS,
          dataIflowRecruitmentCompany: res,
        });
      } else {
        return dispatch({
          type: types.POST_IFLOWRECRUITMENTCOMPANY_FAILURE,
          error: res.response.data.message,
          // dataIflowHiringCompany: res.response,
        });
      }
    })
    /* .catch((err) => {
      return dispatch({
        type: types.POST_IFLOWRECRUITMENTCOMPANY_FAILURE,
        dataIflowHiringCompany: err,
        error: err,
      });
    }); */
  } catch (e) {
    return dispatch({
      type: types.POST_IFLOWRECRUITMENTCOMPANY_FAILURE,
      dataIflowHiringCompany: e,
      error: e,
    });
  }  
};

// post recruitment company.
const postRecruitmentCompanyActionFn = (data) => (dispatch) => {
  dispatch({ type: types.POST_RECRUITMENT_COMPANY_REQUEST });
  return privateAxiosInstance
    .post(`/questionnaire/save-cCompany?processName=Initiation_preScreeningProcess`, data, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.POST_RECRUITMENT_COMPANY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: types.POST_RECRUITMENT_COMPANY_FAILURE,
        payload: err,
      });
    });
};

// edit recruitment company
const editRecruitmentCompanyActionFn = (id, editData) => (dispatch) => {
  dispatch({ type: types.EDIT_RECRUITMENT_COMPANY_REQUEST });
  return privateAxiosInstance
    .put(`/questionnaire/update-company/${id}?processName=Initiation_preScreeningProcess`, editData, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.EDIT_RECRUITMENT_COMPANY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: types.EDIT_RECRUITMENT_COMPANY_FAILURE,
        payload: err,
      });
    });
};

// delete recruitement company
const deleteRecruitmentCompanyActionFn = (id) => (dispatch) => {
  dispatch({ type: types.DELETE_RECRUITMENT_COMPANY_REQUEST });
  return privateAxiosInstance
    .delete(`/questionnaire/delete-company/${id}?processName=Initiation_preScreeningProcess`, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.DELETE_RECRUITMENT_COMPANY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: types.DELETE_RECRUITMENT_COMPANY_FAILURE,
        payload: err,
      });
    });
};

export {
  getRecruitmentCompanyActionFn,
  getIflowChildCompanyUsingParentId,
  postRecruitmentCompanyActionFn,
  editRecruitmentCompanyActionFn,
  deleteRecruitmentCompanyActionFn,
  getRecruitmentCompanyByEmail,
  postRecruitmentCompanyInFlow,
};
