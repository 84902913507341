import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReuseableLabel from "../ReusableComponents/ReuseableLabel";
import { getRecruitmentCompanyByEmail } from "../../Redux/RecruitmentCompany/action";
import { useDispatch, useSelector } from "react-redux";

let flag = 0;
const EmailRepeaters = ({
  emailFormValues = [],
  setEmailFormValues,
  emailValidate,
  setEmailValidate,
  companyId
}) => {
  const dispatch = useDispatch();
  const [addClick, setAddClick] = useState('')
  const [listenerEmail, setListenerEmail] = useState('')
  const [listenerRes, setListenerRes] = useState('');


  const handleChange = (index, field, value) => {
    let updatedFormValues = [...emailFormValues];
    updatedFormValues[index][field] = value;
    const isDuplicate = updatedFormValues.some(
      (emailObj, i) => emailObj.email === value && i !== index
    );

    setListenerEmail(value)
    updatedFormValues[index].isDuplicate = isDuplicate;
    let duplicateEmail = updatedFormValues.filter((val) => val.isDuplicate === true)

    if(duplicateEmail.length > 0){
      updatedFormValues = updatedFormValues.map((val) => val.email != '' ? val : '')
      updatedFormValues = updatedFormValues.filter((val) => val != '')
      setEmailValidate(true)
    }
    setEmailFormValues(updatedFormValues);
  };

  const isDuplicateEmailExist = (email, emails = []) => {
    return emails.some(item => item.email === email);
  };

  const duplicateEmailIdToListenCheck = (listenerRes,addMoreClicked) =>{
    let isDuplicateEmail= false;
    emailFormValues.map((val, key) => {
      if(companyId !== listenerRes[0]['companyId']){
        if(isDuplicateEmailExist(val.email,listenerRes[0]['email'])){
          isDuplicateEmail = true;
          val.isDuplicate = true
          setEmailValidate(true)
          return val
        }
      }
      else{
        if(val.email == listenerEmail){
          val.isDuplicate = false          
          setEmailValidate(false)
          return val
        }
      }      
    })
    console.log("is Duplicate Emailw",isDuplicateEmail)
    console.log("addMoreClicked",addMoreClicked)
    if(!isDuplicateEmail && addMoreClicked){
      console.log("isnise if BLOCK")
      setEmailFormValues([
        ...emailFormValues,
        {
          email: "",
        },
      ]); 
    }
  }
  const checkDuplicateFromCompanies = (value,addMoreClicked) => {
    if(value){
      dispatch(getRecruitmentCompanyByEmail(value)).then((res)=>{
        if(res != undefined){
          console.log('found')
          // setListenerRes(res.payload)
          duplicateEmailIdToListenCheck(res.payload,addMoreClicked)
        }
        else{
          console.log('not found')
          // setListenerRes(false);
          if(addMoreClicked){
            setEmailFormValues([
              ...emailFormValues,
              {
                email: "",
              },
            ]);
          }
        }
      })
    }
  }

  const addFormFields = (emailData) => {
    const addMoreClicked = true;
    setAddClick(true);
    setEmailValidate(false)
    checkDuplicateFromCompanies(emailData.email,addMoreClicked) 
  };

  useEffect(() => {
      const timeOutID = setTimeout(() => {
        checkDuplicateFromCompanies(listenerEmail)        
      },3000)
      return () => clearTimeout(timeOutID);
  },[listenerEmail])

  const removeFormFields = (i) => {
    if(i == 1){
      setAddClick('')
    }
    setEmailValidate(false)
    const updatedFormValues = [...emailFormValues];
    updatedFormValues.splice(i, 1);
    setEmailFormValues(updatedFormValues);
  };  
  return (
    <Stack>
      {emailFormValues &&
        emailFormValues.map((element, index) => (
          <Stack direction={"column"} key={index}>
            <Typography
              sx={{
                fontWeight: 600,
                // color: "black",
                color: "primary.darkText",
                fontFamily: "sans-serif",
                // mb: "10px",
                mb: "5px",
                mt: "10px",
              }}
            >
              Email Ids to Listen :
            </Typography>
            <TextField
              fullWidth
              label={"Email"}
              type="email"
              name="email"
              variant="outlined"
              value={element.email}
              onChange={(e) => handleChange(index, "email", e.target.value)}
              sx={{ marginBottom: "10px" }}
              error={emailValidate&&element.isDuplicate}
              helperText={emailValidate&&element.isDuplicate ? "Please fix the duplicate email issue" : ""}
            />
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              {emailFormValues.length === index + 1 && (
                <Button
                  sx={{
                    height: "40px",
                    borderRadius: "6px",
                    // marginTop: "10px",
                    // background: "#1E0D61",
                    // color: "#fff",
                    backgroundColor: "primary.main",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "primary.dark" },
                  }}
                  type="button"
                  disabled={element.isDuplicate}
                  onClick={() => addFormFields(element)}
                >
                  Add more
                </Button>
              )}
              {index ? (
                <Button
                  sx={{
                    height: "40px",
                    width: "20px",
                    borderRadius: "6px",
                    //  marginLeft:"15px",
                    // margin: "5px 15px 0 4px",
                    textTransform: "none",
                    // background: "#FE9A25",
                    backgroundColor: "secondary.main",
                    color: "#fff",
                    "&:hover": {
                      //backgroundColor: "#e9983c"
                      backgroundColor: "secondary.dark",
                    },
                  }}
                  type="button"
                  onClick={() => removeFormFields(index)}
                >
                  Remove
                </Button>
              ) : null}
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

export default EmailRepeaters;
