import * as types from "./actionTypes";
import { getLocalStorageData } from "../../utils/localStorage";
import { environmentDetails } from "../../../src/environment/environment";
import axios from "axios";
import { refreshTokenHeadersObject } from "../../utils/headers";
import { privateAxiosInstance } from "../../utils/axiosInstance";

const apiUrl = environmentDetails.templateFetchUrl;
const childCompaniesUrl = environmentDetails.childCompaniesUrl;
const templateIndexUrl = environmentDetails.templateIndexUrl;
const metaUrl = environmentDetails.metaUrl;
const jrmsApiUrl = environmentDetails.apiUrl;
//const isUserLoggedIn = getLocalStorageData("userLogin");
const userToken=environmentDetails?.metaPermanentToken;

const getChildCompanies = () => (dispatch) => {
  dispatch({ type: types.GET_CHILDCOMPANIES_REQUEST });
  return axios
    .get(
      childCompaniesUrl,
      { headers: { Authorization: `Bearer ${userToken}` } }
    )
    .then((res) => {
     // console.log("res", res);
      return dispatch({
        type: types.GET_CHILDCOMPANIES_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.GET_CHILDCOMPANIES_FAILURE, payload: err });
    });
};

const getCompanyNumber = (id) => (dispatch) => {
  dispatch({ type: types.GET_CHILDCOMPANIES_REQUEST });
  return axios
    .get(
      metaUrl+id+"/phone_numbers",
      { headers: { Authorization: `Bearer ${userToken}` } }
    )
    .then((res) => {
     // console.log("res", res);
      return dispatch({
        type: types.GET_CHILDCOMPANIES_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.GET_CHILDCOMPANIES_FAILURE, payload: err });
    });
};

const getTemplateNameList = (templateID) => (dispatch) => {
  dispatch({ type: types.GET_TEMPLATENAME_REQUEST });
  return privateAxiosInstance
    .post(
      jrmsApiUrl+"questionnaire/getTemplateNameList?processName=Initiation_preScreeningProcess",
      {templateID: templateID},
      { headers: { ...refreshTokenHeadersObject() } }
    )
    .then((res) => {
     // console.log("templateResponse", res);
      return dispatch({
        type: types.GET_TEMPLATENAME_SUCCESS,
        templatePayload: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.GET_TEMPLATENAME_FAILURE, templatePayload: err });
    });
};

const getTemplateName = () => (dispatch) => {
  dispatch({ type: types.GET_TEMPLATENAME_REQUEST });
  return privateAxiosInstance
    .get(
      apiUrl,
      { headers: { Authorization: `Bearer ${userToken}` } }
    )
    .then((res) => {
     // console.log("res", res);
      return dispatch({
        type: types.GET_TEMPLATENAME_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.GET_TEMPLATENAME_FAILURE, payload: err });
    });
};

const getTemplateIndex = () => (dispatch) => {
  dispatch({ type: types.GET_TEMPLATEINDEX_REQUEST });
  return privateAxiosInstance
    .get(
      `${templateIndexUrl}?processName=Initiation_preScreeningProcess`,
      { headers: { ...refreshTokenHeadersObject() } }
    )
    .then((res) => {
     // console.log("res", res);
      return dispatch({
        type: types.GET_TEMPLATEINDEX_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.GET_TEMPLATEINDEX_FAILURE, payload: err });
    });
};

export { getChildCompanies, getTemplateIndex, getCompanyNumber, getTemplateNameList, getTemplateName };
