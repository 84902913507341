import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../Components/Sidebar/SideBar";
import ProfileTopBar from "../Components/ProfileTopBar/ProfileTopBar";
import SearchFeild from "../Components/Table/SearchFeild";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getHiringCompanyData,
  postHiringCompanyData,
  editHiringCompanyData,
  deleteHiringCompanyData,
  editCompanyStatus,
  getIflowHirngCompany,
  getHiringCompanyThroughData,
  postHiringCompanyInFlow,
} from "../Redux/HiringCompany/action";
import HiringCompanyTable from "../Components/Table/HiringCompanyTable";
import { getProcessName } from "../Redux/IFlowData/action";
import { useDebounce } from "../hooks/useDebounce";
import ReuseableLabel from "../Components/ReusableComponents/ReuseableLabel";
import { schema } from "../Components/Table/EditHiringCompanyModal";
import axios from "axios";
import { accessTokenHeadersObject } from "../utils/headers";
import ApiAlert from "../Components/AlertBox/ApiAlert";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "85%",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export const initailState = {
  companyId: "",
  name: "",
  companyType: "master",
  website: "",
  processName: "",
  // processlabel:"",
  verifyToken: "",
  bucketName: "",
  waToken: "",
  /*phone_number_internal: "",
  phone_number_id_internal: "",*/
  waToken: "",
  baseUrl: "",
  sessionTableName: "",
  linkedinPage: "",
  instagramPage: "",
  careerPage: "",
  xHandle: "",
};

const HiringTabDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState("");
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [processObject, setProcessObject] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertContent, setalertcontent] = useState("");
  const [alertStatus, setAlertStatus] = useState(Boolean);
  const { debouncedValue } = useDebounce(searchVal, 500);
  const filter = createFilterOptions();
  const [valuex, setValuex] = useState(null);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [iflowCompanyData, setIflowCompanyData] = useState("");
  const [iflowCompanyDataId, setIflowCompanyDataId] = useState("");
  // console.log("iflowCompanyData", iflowCompanyData);
  const [dialogValue, setDialogValue] = useState({
    name: "",
  });

  const HiringhandleClose = () => {
    setDialogValue({
      name: "",
    });
    setToggleOpen(false);
  };

  // console.log("value", valuex);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset(initailState);
    setValuex(null);
    setOpen(false);
  };

  // console.log("ilfowData", iflowInitialData.resource.name);

  const hiringComapanyData = useSelector(
    (state) => state.hiringCompanyReducer.hiringCompany
  );
  const hiringComapanyDataLength = useSelector(
    (state) => state.hiringCompanyReducer.length
  );
  const processNameData = useSelector(
    (state) => state.iflowDataReducer.processName
  );
  const iflowHiringcompanyData = useSelector(
    (state) => state.hiringCompanyReducer.iflowHiringCompany
  );
  // console.log("iflowHiringcompany",iflowHiringcompanyData)

  const [selectedIndex, setSelectedIndex] = useState(0);
  //console.log("P-N", processNameData);

  // console.log("hiringComapanyDataLength", hiringComapanyDataLength);
  const getHiringCompanyFn = () => {
    dispatch(getHiringCompanyData(page + 1, rowsPerPage, searchVal));
  };

  useEffect(() => {
    dispatch(getIflowHirngCompany());
  }, []);

  useEffect(() => {
    //dispatch(getHiringCompanyData(page + 1, rowsPerPage));
    if (searchVal) {
      getHiringCompanyFn();
      // console.log("useEffect called react : ",searchVal)
    } else {
      getHiringCompanyFn();
      dispatch(getProcessName());
    }
  }, [page, rowsPerPage, debouncedValue]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: initailState,
    resolver: yupResolver(schema),
  });
  const allProcess = processNameData[0];
  // console.log("allProcess", allProcess);

  const hiringCompanyName = watch("name");
  const bucketName = watch("bucketName");
  const sessionTableName = watch("sessionTableName");
  const baseUrl = watch("baseUrl");
  const hiringCompanyName_bucketname = hiringCompanyName + "-" + bucketName;
  // hiringCompanyName.toLowerCase();
  const hiringCompanyName_sessionTableName =
    hiringCompanyName + "-" + sessionTableName;
  // hiringCompanyName_sessionTableName.toLowerCase()
  const processName = watch("processName");
  const selectedProcessNameObject = processNameData[processName];

  // console.log("wwwwww",selectedProcessNameObject)
  const processAppURL =
    selectedProcessNameObject?.processDef?.emailConfiguration?.appurl;

  //const [baseURL, setBaseURL] = useState(processAppURL); // State to hold baseUrl value
  //const baseUrlRef = useRef(null); // Ref for the TextField

  //console.log("dddddddddddd", baseURL);

  useEffect(() => {
    // console.log("sss");
    // setValue("name", valuex?.name);
    setValue("baseUrl", processAppURL);
  }, [setValue, processAppURL]);

  // console.log("iflowCompanyData", iflowCompanyData);

  const handleiFlowData = (valuex) => {
    //console.log(valuex);
    dispatch(
      postHiringCompanyInFlow({
        resource: {
          name: valuex,
          code: null,
          friendlyname: "",
          parentid: "",
          comptype: "CUSTOMER",
          contacts: [],
          aliases: [],
          vendorsapid: [],
          customersapid: [],
          accountManagers: [],
          otherEmails: [],
          otherPhoneNumbers: [],
          partnerLevel: "",
          addresses: [
            {
              line: "",
              country: "India",
              state: "",
              city: "",
              postalcode: "",
              type: "PA",
              friendlyaddress: "",
            },
          ],
          group: null,
          primaryEmailId: "",
          primaryPhoneNumber: "",
          registered: "India",
          employeeName: "",
          employeeAddress: "",
          dob: "",
          aadhaarId: "",
          panId: "",
          companyId: "",
          gstinIds: [],
          ieCode: "",
          blackList: false,
          isactive: true,
          aadhaar: {
            aadhaarId: "",
            name: "",
            address: "",
            gender: "",
            care_of: "",
            dob: "",
            email: "",
            id: "",
            message: "",
            mobile_hash: "",
            photo_link: "",
            ref_id: "",
            split_address: null,
            status: "",
            year_of_birth: "",
          },
          pan: {
            id: "",
            name: "",
            dob: "",
            fathersName: "",
          },
          flags: {
            mobilemsa: null,
            voicemsa: null,
            smsmsa: null,
            iotmsa: null,
            nda: null,
            ndaiot: null,
          },
          processName: "Initiation Process Pre-Screening",
        },
      })
    )
      .then((res) => {
        if (res.type === "POST_IFLOWHIRINGCOMPANY_SUCCESS") {
          setIflowCompanyDataId(res.dataIflowHiringCompany)
          dispatch(getHiringCompanyThroughData(res?.dataIflowHiringCompany))
            .then((res) => {
              setIflowCompanyData(res?.payload?.code);
            })
            .catch((err) => {
              console.log(err);
            });
        }else{
        setValuex("")
        setAlert(true);
        setAlertStatus(false);
        setalertcontent(res.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubmit = (data) => {
    // console.log("data",data);
    // console.log("bucketData",data.bucketName);
    // console.log("ww", data.processName);
    const bucketName = hiringCompanyName_bucketname.toLowerCase();
    const sessionTableName = hiringCompanyName_sessionTableName.toLowerCase();
    const allProcess = processNameData[data.processName];
    const allProcessRequireObject = {
      processName: allProcess?.processname,
      description: allProcess?.description,
      prefix: allProcess?.prefix,
      processlabel: allProcess?.processlabel,
      processkey: allProcess?.processkey,
    };
    const finalObjectData = {
      ...data,
      ...allProcessRequireObject,
      companyId: valuex?.code ? valuex.code : iflowCompanyData,
      iFlowCompanyId:valuex?.id?valuex.id:iflowCompanyDataId,
      bucketName,
      sessionTableName,
    };
    console.log('finalObjectData=> ',finalObjectData);
    dispatch(postHiringCompanyData(finalObjectData))
      .then((res) => {
        // console.log("POST RES -",res)
        handleClose();
        reset();
        // setAlert(true);
        // setAlertStatus(res.payload.status);
        // setalertcontent(res.payload.message);
        dispatch(getHiringCompanyData());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(()=>{
        setValuex("");
        setIflowCompanyDataId("");
        setIflowCompanyData("");
      })
      
  };

  // const deleteHiringCompany = (hiringCompanyId) => {
  //   dispatch(deleteHiringCompanyData(hiringCompanyId))
  //     .then((res) => {
  //       console.log(res);
  //       setOpenDeleteModal(false);
  //       getHiringCompanyFn();
  //       // dispatch(getHiringCompanyData());
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleChangeSwitch = (data) => {
    var statusChange;
    if (data.companyStatus === true) {
      statusChange = false;
    } else if (data.companyStatus === false) {
      statusChange = true;
    }
    dispatch(
      editCompanyStatus(data.CompanyId, statusChange, {
        companyStatus: statusChange,
      })
    )
      .then((res) => {
        if (res.payload.status === 200) {
          setAlert(true);
          setAlertStatus(res.payload.data.status);
          setalertcontent(res.payload.data.message);
        } else {
          setAlert(true);
          setAlertStatus(res.payload.response.data.status);
          setalertcontent(res.payload.response.data.message);
        }
        setOpenDeleteModal(false);
        getHiringCompanyFn();
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const AlerthandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };
  return (
    <>
      {alert && (
        <ApiAlert
          open={alert}
          snackbaronClose={AlerthandleClose}
          alertonClose={AlerthandleClose}
          alertType={alertStatus ? "success" : "error"}
          Content={alertContent}
        />
      )}
      <Box sx={{ width: "100%" }}>
        <SearchFeild
          onChange={(e) => setSearchVal(e.target.value)}
          buttonName="Add Hiring Company"
          Navigate={handleOpen}
          width="100%"
          placeholder="Search Hiring Company"
          TextFieldWidth="30%"
          marginBottom={"15px"}
          roleModule="Hiring Company"
          //medium="0px"
        />
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form>
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    gap: "15px",
                    border: "0px solid blue",
                    // color: "#2B2A2C",
                    color: "primary.darkText",
                    fontSize: "30px",
                    fontWeight: 600,
                  }}
                >
                  Add Hiring Company
                </Typography>
                <Box mt={"20px"}>
                  <Stack direction={"row"} gap={2}>
                    <Controller
                      name="name"
                      control={control}
                      // defaultValue={null}
                      // rules={{ required: "Please select an option" }} // Validation rule
                      render={({ field }) => (
                        <Autocomplete
                          sx={{ width: "50%" }}
                          value={valuex}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setValuex({
                                name: newValue,
                              });
                            } else if (newValue && newValue.inputValue) {
                              handleiFlowData(newValue.inputValue);
                              // Create a new value from the user input
                              setValuex({
                                name: newValue.inputValue,
                              });
                            } else {
                              setValuex(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const { inputValue } = params;

                            const uniqueNames = new Set();

                            const filtered = options.filter((option) => {
                              const inputLowerCase = inputValue.toLowerCase();
                              const optionNameLowerCase =
                                option.name.toLowerCase();

                              if (uniqueNames.has(optionNameLowerCase)) {
                                return false; // Skip this option if the name is a duplicate
                              }
                              uniqueNames.add(optionNameLowerCase);

                              return true;
                            });

                            const matchedOptions = filtered.filter((option) =>
                              option.name
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            );

                            if (
                              inputValue !== "" &&
                              !uniqueNames.has(inputValue.toLowerCase())
                            ) {
                              matchedOptions.push({
                                inputValue,
                                name: `Add "${inputValue}"`,
                              });
                            }

                            return matchedOptions;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={iflowHiringcompanyData}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option.name}</li>
                          )}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=<ReuseableLabel label={"Hiring Company"}/>
                              {...register("name")}
                              error={Boolean(errors.name)}
                              helperText={errors.name?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <IconButton
                      onClick={handleClose}
                      sx={{ position: "absolute", top: "0", right: "0" }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <TextField
                      sx={{ width: "50%" }}
                      id="filled-multiline-flexible"
                      name="website"
                      label=<ReuseableLabel label={"Company Website"} />
                      {...register("website")}
                      error={Boolean(errors.website)}
                      helperText={errors.website?.message}
                      // style={style}
                      InputProps={{ sx: { borderRadius: "6px" } }}
                    />
                  </Stack>
                  <Stack direction={"row"} gap={2} mt={"12px"}>
                    <FormControl
                      variant="outlined"
                      style={{ marginTop: 1, minWidth: 150 }}
                      size="md"
                      fullWidth
                    >
                      <InputLabel>
                        <ReuseableLabel label={"Process Name"} />
                      </InputLabel>
                      <Controller
                        name="processName"
                        control={control}
                        defaultValue=""
                        // rules={{ required: "Please select an option" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              label=<ReuseableLabel label={"Process Name"} />
                              id="demo-simple-select-required"
                              labelId="demo-simple-select-required-label"
                              error={Boolean(errors.processName)}
                              // onChange={(e) => {
                              //   const selectedIndex = e.target.value;
                              //   const selectedObject =
                              //     processNameData[selectedIndex];
                              //   setValue("processName", selectedObject); // Set the selected object as the form value
                              // }}
                            >
                              <MenuItem value="">Select an option</MenuItem>
                              {processNameData.map((el, index) => {
                                return (
                                  <MenuItem value={index} key={index}>
                                    {el.processname}
                                  </MenuItem>
                                );
                              })}
                              {/* <MenuItem value="">Select an option</MenuItem>
                                <MenuItem value="y">Process Name 1</MenuItem>
                                <MenuItem value="n">Process Name 2</MenuItem>
                                <MenuItem value="c">Process Name 3</MenuItem> */}
                            </Select>
                            {errors.processName && (
                              <FormHelperText error>
                                {errors.processName.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction={"row"} gap={2} mt={"12px"}>
                    <TextField
                      // ref={baseUrlRef.current}
                      // inputRef={baseUrlRef}
                      // inputRef={baseUrlRef}
                      inputProps={{ readOnly: true }}
                      sx={{ width: "100%" }}
                      id="filled-multiline-flexible"
                      name="baseUrl"
                      label=<ReuseableLabel label={"Base URL"} />
                      value={processAppURL}
                      InputLabelProps={{
                        shrink: processAppURL !== undefined ? true : false,
                      }}
                      {...register("baseUrl")}
                      error={Boolean(errors.baseUrl)}
                      helperText={errors.baseUrl?.message}
                      // style={style}
                      InputProps={{ sx: { borderRadius: "6px" } }}
                      // focused={processAppURL !== undefined}
                    />
                    <TextField
                      sx={{ width: "100%" }}
                      id="filled-multiline-flexible"
                      name="verifyToken"
                      label=<ReuseableLabel label={"Verify Token"} />
                      {...register("verifyToken")}
                      error={Boolean(errors.verifyToken)}
                      helperText={errors.verifyToken?.message}
                      // style={style}
                      InputProps={{ sx: { borderRadius: "6px" } }}
                    />
                  </Stack>
                  <Stack direction={"row"} gap={2} mt={"12px"}>
                    <TextField
                      sx={{ width: "100%" }}
                      id="filled-multiline-flexible"
                      name="bucketName"
                      label=<ReuseableLabel label={"Bucket Name"} />
                      {...register("bucketName")}
                      error={Boolean(errors.bucketName)}
                      helperText={errors.bucketName?.message}
                      // style={style}
                      InputProps={{ sx: { borderRadius: "6px" } }}
                    />
                    {/*<TextField
                      sx={{ width: "100%" }}
                      id="filled-multiline-flexible"
                      name="phone_number_internal"
                      label="Phone Number Internal*"
                      {...register("phone_number_internal")}
                      error={Boolean(errors.phone_number_internal)}
                      helperText={errors.phone_number_internal?.message}
                      // style={style}
                      InputProps={{ sx: { borderRadius: "6px" } }}
                    />*/}
                  </Stack>
                  <Stack direction={"row"} gap={2} mt={"12px"}>
                    {/*<TextField
                      sx={{ width: "100%" }}
                      id="filled-multiline-flexible"
                      name="phone_number_id_internal"
                      label="Phone Numbet Id Internal*"
                      {...register("phone_number_id_internal")}
                      error={Boolean(errors.phone_number_id_internal)}
                      helperText={errors.phone_number_id_internal?.message}
                      // style={style}
                      InputProps={{ sx: { borderRadius: "6px" } }}
                    />*/}
                    <TextField
                      sx={{ width: "100%" }}
                      id="filled-multiline-flexible"
                      name="sessionTableName"
                      label=<ReuseableLabel label={"Session Table Name"} />
                      {...register("sessionTableName")}
                      error={Boolean(errors.sessionTableName)}
                      helperText={errors.sessionTableName?.message}
                      // style={style}
                      InputProps={{ sx: { borderRadius: "6px" } }}
                    />
                  </Stack>
                  <Stack direction={"row"} gap={2} mt={"12px"}>
                    <TextField
                      multiline
                      rows={2}
                      sx={{ width: "100%" }}
                      id="filled-multiline-flexible"
                      name="waToken"
                      label="WhatsApp Token"
                      {...register("waToken")}
                      // error={Boolean(errors.waToken)}
                      // helperText={errors.waToken?.message}
                      // style={style}
                      InputProps={{ sx: { borderRadius: "6px" } }}
                    />
                  </Stack>
                </Box>
                <Stack direction={"row"} gap={2} mt={"12px"}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name=""
                    label="LinkedIn Page"
                    {...register("linkedinPage")}
                    // error={Boolean(errors.linkedinPage)}
                    // helperText={errors.baseUrl?.message}
                    //  style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name=""
                    label="Instagram Page"
                    {...register("instagramPage")}
                    // error={Boolean(errors.verifyToken)}
                    // helperText={errors.verifyToken?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                </Stack>
                <Stack direction={"row"} gap={2} mt={"12px"}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name=""
                    label="Career Page"
                    {...register("careerPage")}
                    // error={Boolean(errors.baseUrl)}
                    // helperText={errors.baseUrl?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name=""
                    label="X Handle"
                    {...register("xHandle")}
                    // error={Boolean(errors.verifyToken)}
                    // helperText={errors.verifyToken?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"right"}
                  spacing={2}
                  sx={{ width: "100%" }}
                  mt={"15px"}
                >
                  <Button
                    sx={{
                      borderRadius: "6px",
                      textTransform: "none",
                      // background: "#1E0D61",
                      // color: "#fff",
                      backgroundColor: "primary.main",
                      color: "secondary.main",
                      //"&:hover": { background: "#220986" },
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={handleClose}
                    sx={{
                      borderRadius: "6px",
                      textTransform: "none",
                      // background: "#FE9A25",
                      // color: "#fff",
                      backgroundColor: "secondary.main",
                      color: "primary.main",
                      "&:hover": { background: "#e9983c" },
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </form>
          </Box>
        </Modal>
        <HiringCompanyTable
          hiringComapanyData={hiringComapanyData}
          handleChangeSwitch={handleChangeSwitch}
          page={page}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          hiringComapanyDataLength={hiringComapanyDataLength}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          getHiringCompanyFn={getHiringCompanyFn}
        />
      </Box>
    </>
  );
};

export default HiringTabDashboard;
