export const environmentDetails = {
  apiUrl: "https://dev.digisherpa.ai/",
  signinApiUrl: "https://dev.digisherpa.ai/questionnaire/login",
  processApiUrl: "https://dev.digisherpa.ai/questionnaire/get-all-process-variable",
  templateFetchUrl: "https://graph.facebook.com/v18.0/108098652370903/message_templates?category=utility",
  metaUrl: "https://graph.facebook.com/v18.0/",
  // childCompaniesUrl: "https://graph.facebook.com/v18.0/648515853026781/owned_whatsapp_business_accounts", //for alpha-q
  childCompaniesUrl: "https://graph.facebook.com/v18.0/648515853026781/owned_whatsapp_business_accounts",
  // metaPermanentToken: "EAAsZAEJcI4JsBO6Ep0VEq4zOQ3Ext0FrW6HNSlUm85EGSEB2V7DKNEtiAKUVRaBzQdJpubwtjlpFlQbhI0OjftknvsZASQIbXhh1HqkerOFvtgzdgYVS1zkArmdQxhIEUwDYIE2eUK6l6DtRXYE7dMz3MFGGoFJi5R0cgP7nNBiEkEbF6z3pcfYSihnUZBkA7OVilcD7ZB23vD4a", //for alpha-q
  metaPermanentToken: "EAAsZAEJcI4JsBOZCTmpxrZCo9XAca3QdiP0ChDompqwdZAccRP9D8ZBo2Tdgc7r0Nn9ZCDWe6p8lPZAOGWBQgQTlDQPqniZBljE0C0tSslAbvHo3gbTxEnhzUH4YUKNMS9skQYERbmbRvIVXEmOk71ZBB1Ap7kOh9OBjBKKyh2C9siiFWIQTxkKp2kwgIwbDaAgZApHAZDZD",
  themeApiUrl: "https://dev.digisherpa.ai/questionnaire/get-theme-details",
  themeDefinationApiUrl: "https://dev.digisherpa.ai/questionnaire/get-theme-defination-details",
  templateIndexUrl: "https://dev.digisherpa.ai/questionnaire/get-template-index",
  countryUrl: "https://countriesnow.space/api/v0.1/countries",
  cityUrl: "https://countriesnow.space/api/v0.1/countries/cities",
  jobOpeningUrl: "https://development.digisherpa.ai/ats-intg/jobs?atsName=zoho&Job_Opening_Status=In-progress",
  processDefinationUrl: "https://pilot-dev.reca.ai/bpmnapi/metadata/variables/processes/defination?processname=Initiation%20Process%20Pre-Screening",
  logoUrl: "https://jrmspublicdirectory.s3.amazonaws.com/digital-sherpa-logo.svg",
};
