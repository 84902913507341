import React, { useEffect, useState } from "react";
import QuestionInput from "./QuestionInput";
import {
  Stack,
  Slider,
  Box,
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  ClickAwayListener,
  Menu,
} from "@mui/material";
import TemplateSection from "./TemplateSection";
import BotSection from "./BotSection";
import GeneralSection from "./GeneralSection";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  getQuestionsData,
  editQuestionData,
  postQuestionsData,
} from "../../Redux/Questions/action";
import { useDispatch, useSelector } from "react-redux";
import ApiAlert from "../AlertBox/ApiAlert";
import { getProcessName } from "../../Redux/IFlowData/action";
import TemplateRepeater from "../../Pages/TemplateRepeater.jsx";
import ReuseableLabel from "../ReusableComponents/ReuseableLabel.jsx";
import {
  extractUnCommonWords,
  findSameQuestion2,
  findSimilarQuestions,
  getMasterTooltipMessage,
  isContainsDoubleDollar,
} from "../../utils/helperFunctions.js";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "../../constants/masterQuestion.js";
import { Questions, qs } from "../../constants/masterQuestion.js";
import DeleteAlert from "../AlertBox/DeleteAlert.jsx";
import { useThrottle } from "../../hooks/useThrottle";
import {
  getAllDocumentCategoriesActionFn,
  getDocumentSubCategoriesActionFn,
} from "../../Redux/documentCategory/action.js";
import { InfoOutlined } from "@mui/icons-material";
import { infoImg } from "../../assets/index.js";
import InfoBox from "../Common/InfoBox.jsx";
const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "blue",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 25,
    height: 25,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "blue",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export const initailState = {
  Question: "",
  "Job Description": "",
  Weight: "",
  Ack: "",
  "Process Variable": "",
  defaultValue: "",
  "Bot Interpreted": "",
  "Bot Name": "",
  Path: "",
  Adaptive: "",
  yPath: "",
  nPath: "",
  "Next Question Prefix": "",
  "Help Text": "",
  "Template Name": "",
  template_variable: "",
  expectDocument: "no",
  questionType: "",
  rangeStart: "",
  rangeEnd: "",
  mandatoryQuestion: "no",
  documentType: "",
  questionExpiry: "",
  "Attach Job Description": "n",
  "Scoring Engine Field Name": "",
  documentCategory: "",
  // Type: "Plain",
  // Option1: "",
  // Option2: "",
  // Option3: "",
  // "Processing Needed": "",
};
const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [alertContent, setalertcontent] = useState("");
  const [alertStatus, setAlertStatus] = useState(Boolean);
  const [recruiterCompanyDecLength, setRecruiterCompanyDecLength] = useState(0);
  const [isContainsDDollar, setIsContainsDDollar] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [masterQuestions, setMasterQuestions] = useState([]);
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [similarQuestinToSave, setSimilarQuestionToSave] = useState(null);
  const [isSameQuestionExist, setIsSameQuestionExist] = useState(false);
  const [documentCategories, setDocumentCategories] = useState([]);
  const [documentSubCategories, setDocumentSubCategories] = useState([]);
  const [selectedDocumentCategoryItem, setSelectedDocumentCategoryItem] =
    useState(null);
  const throlltedFunction = useThrottle();

  //const [processVariables, setProcessVariables] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: initailState,
    resolver: yupResolver(schema),
  });
  // DOCUMENT CATEGORY WORK IN PROGESS
  const expectDocumentWatch = watch("expectDocument");
  const documentTypeWatch = watch("documentType");
  const documentCategoryWatch = watch("documentCategory");
  const botInterpretedWatch = watch("Bot Interpreted");
  const botNameWatch = watch("Bot Name");
  const processVariableWatch = watch("Process Variable");

  const processNameData = useSelector(
    (state) => state.iflowDataReducer.processName
  );
  const masterQues = useSelector((state) => state);

  const handleDescriptionKeyUp = (event) => {
    setRecruiterCompanyDecLength(event.target.value.length);
  };

  // console.log("sscccccheema  ",schema);
  const createMasterQuestionDispatchFn = (data) => {
    dispatch(postQuestionsData(data))
      .then((res) => {
        if (res.type === "POST_QUESTIONS_SUCCESS") {
          setAlert(true);
          setAlertStatus(res.payload.status);
          setalertcontent(res.payload.message);
          console.log("postData", res);
          // dispatch(getQuestionsData());
          // navigate("/questions",{ state : {alertContent : res.payload.message, alertStatus: res.payload.status} });
          navigate("/questions");
          let questionToast = JSON.stringify({
            alertContent: res.payload.message,
            alertStatus: res.payload.status,
          });
          localStorage.setItem("questionToast", questionToast);
        } else {
          console.log("post new question erro");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (data) => {
    let title = data.Question;
    let unCommonWords = extractUnCommonWords(title);
    data.keywords = unCommonWords;
    // let templateDetails = template;
    // data["Template Name"] = templateDetails[0]["templateName"];
    if (filteredQuestions.length > 0) {
      setOpen(true);
      setSimilarQuestionToSave(data);
      return;
    }
    createMasterQuestionDispatchFn(data);
    // dispatch(postQuestionsData(data))
    //   .then((res) => {
    //     if (res.type === "POST_QUESTIONS_SUCCESS") {
    //       setAlert(true);
    //       setAlertStatus(res.payload.status);
    //       setalertcontent(res.payload.message);
    //       //console.log("postData", res);
    //       // dispatch(getQuestionsData());
    //       // navigate("/questions",{ state : {alertContent : res.payload.message, alertStatus: res.payload.status} });
    //       navigate("/questions");
    //       let questionToast = JSON.stringify({
    //         alertContent: res.payload.message,
    //         alertStatus: res.payload.status,
    //       });
    //       localStorage.setItem("questionToast", questionToast);
    //     } else {
    //       console.log("post new question erro");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const handleSimilarQuestionSubmit = () => {
    createMasterQuestionDispatchFn(similarQuestinToSave);
    handleClose();
    //console.log("handle similar question submit ",similarQuestinToSave);
  };
  const [botInterpreted, setBotInterpreted] = useState();
  const [botNameDisabled, setBotNameDisabled] = useState(false);

  const handleBotInterpreted = (event) => {
    let curval = event.target.value;
    // console.log(curval)
    setBotInterpreted(curval);
    setBotNameDisabled(false);
    if (curval == "n") {
      setBotNameDisabled(true);
    }
  };

  useEffect(() => {
    dispatch(getProcessName());
    dispatch(getQuestionsData(1, 5000)).then((res) => {
      if (res.type === "GET_QUESTIONS_SUCCESS") {
        setMasterQuestions(res.payload);
      } else {
        console.log("fetching master question error", res);
      }
    });
  }, []);
  // DOCUMENT CATEGORY WORK IN PROGESS
  useEffect(() => {
    // DOCUMENT CATEGORY WORK IN PROGESS
    if (expectDocumentWatch === "yes" && !documentCategories.length) {
      dispatch(getAllDocumentCategoriesActionFn()).then((res) => {
        if (res.type === "GET_DOCUMENT_CATEGORIES_SUCCESS") {
          setDocumentCategories(res.payload);
        } else {
          setDocumentCategories([]);
        }
      });
    }
    if (expectDocumentWatch === "no") {
      setValue("documentType", "");
      setValue("documentCategory", "");
    }
    if (botInterpretedWatch === "n") {
      setValue("Bot Name", "");
    }
  }, [expectDocumentWatch, botInterpretedWatch]);

  var processVariables = [];
  processNameData.map((val) => {
    if (val.processkey == "Initiation_preScreeningProcess") {
      processVariables = val.processDef.variables;
    }
  });

  // console.log(processVariables, "aaaaaaaa");

  const AlerthandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };
  const [template, setTemplate] = useState([
    { templateName: "", paramNumber: "", params: [], verbiage: "" },
  ]);

  const onQuestionTitleKeyUp = (e) => {
    let value = e.target.value;
    console.log("value", e);
    if (e.keyCode != 27) setMenuOpen(true);
    else setMenuOpen(false);
    let res = isContainsDoubleDollar(value);
    if (res) {
      setIsContainsDDollar(true);
    } else {
      setIsContainsDDollar(false);
    }
    let throttleFunc = throlltedFunction(
      (value) => handleThrottleFunction(value),
      800
    );
    throttleFunc(value);
  };

  const handleTab = (e) => {
    if (e.keyCode != 9) setMenuOpen(true);
    else setMenuOpen(false);
  };

  let keyWordsQues = masterQuestions?.filter((ques) => ques.keywords);
  const handleThrottleFunction = (input) => {
    let similarQues = findSimilarQuestions(input, keyWordsQues);
    if (input && similarQues.length > 0) {
      let sameQuestion = findSameQuestion2(input, similarQues);
      // console.log("Same question is ", sameQuestion);
      if (sameQuestion.length > 0) {
        setIsSameQuestionExist(true);
      } else {
        setIsSameQuestionExist(false);
      }
      setFilteredQuestions(similarQues);
    } else {
      setFilteredQuestions([]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = (question) => {
    //reset({Question:question.Question});

    setMenuOpen(false);
    // console.log("Selected question  ", question);
  };

  // DOCUMENT CATEGORY WORK IN PROGESS
  useEffect(() => {
    if (documentCategoryWatch === "KYC" && !documentSubCategories.length) {
      dispatch(getDocumentSubCategoriesActionFn()).then((res) => {
        if (res.type === "GET_DOCUMENT_SUB_CATEGORIES_SUCCESS") {
          setDocumentSubCategories(res.payload);
        }
      });
    } else {
      setDocumentSubCategories([]);
      setValue("documentType", "");
      //console.log("eeeeeeeee ", event.target.value);
    }
  }, [documentCategoryWatch]);

  return (
    <Box
      sx={{
        width: "986px",
        margin: "auto",
        boxSizing: "border-box",
        paddingLeft: "50px",
        paddingRight: "50px",
      }}
    >
      {alert && (
        <ApiAlert
          open={alert}
          snackbaronClose={AlerthandleClose}
          alertonClose={AlerthandleClose}
          alertType={alertStatus ? "success" : "error"}
          Content={alertContent}
        />
      )}
      {/* <h1 style={{ height: "auto" }}>Add Question</h1> */}
      <Typography
        variant="h4"
        component="h2"
        style={{ marginBottom: "15px", marginTop: 0 }}
      >
        Add Master Question
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* QUESTION  */}
        <Box
          sx={{
            width: "100%",
            position: "relative",
          }}
        >
          <TextField
            fullWidth
            //multiline
            variant="outlined"
            autoComplete="off"
            type="text"
            // label="Question*"
            label={<ReuseableLabel label={"Question"} />}
            name="Question"
            maxRows={2}
            placeholder="Add Your Question here"
            InputProps={{ sx: { borderRadius: "6px" } }}
            style={{ width: "100%", height: "auto" }}
            {...register("Question", { required: "Question is Required" })}
            onKeyUp={onQuestionTitleKeyUp}
            onKeyDown={handleTab}
            error={Boolean(errors.Question)}
            helperText={errors.Question?.message}
          />
          {filteredQuestions.length > 0 && (
            <Box
              sx={{
                height: "fitcontent",
                maxHeight: "200px",
                overflowY: "auto",
                overflowX: "auto",
                boxShadow: 3,
                borderBottomRightRadius: "7px",
                borderBottomLeftRadius: "7px",
                // border: "1px solid black",
              }}
            >
              {/* <Chip label="Questions already exists in Master Questions list." variant="outlined" color="secondary" size="small" /> */}
              <>
                {filteredQuestions.map((item, idx) => {
                  return (
                    <ClickAwayListener onClickAway={handleCloseMenu}>
                      {menuOpen ? (
                        <MenuItem
                          onClick={() => {
                            handleMenuItemClick(item);
                          }}
                          key={idx}
                        >
                          {item.Question}
                        </MenuItem>
                      ) : (
                        <></>
                      )}
                    </ClickAwayListener>
                  );
                })}
              </>
            </Box>
          )}
        </Box>
        <>
          {/* DESCRIPTION  */}
          {/*
        <Box
          sx={{
            width: "100%",
            position: "relative",
            margin: "20px 0 34px 0",
          }}
        >
          <TextField
            multiline
            style={{ width: "100%" }}
            label="Question Job Description"
            variant="outlined"
            {...register("Job Description")}
            inputProps={{ maxLength: 4000 }}
            //maxRows={5}
            minRows={5}
            rows={5}
            onKeyUp={handleDescriptionKeyUp}
          />
          <span
            style={{
              fontSize: "11px",
              float: "right",
              right: "4px",
              position: "relative",
              margin: "6px 0 10px 0",
              color: `${recruiterCompanyDecLength < 4000 ? "gray" : "red"}`,
            }}
          >
            {recruiterCompanyDecLength}/4000
          </span>
        </Box>
        */}
        </>
        <Box
          sx={{
            width: "30%",
            mt: "20px",
          }}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">Attach Job Description</FormLabel>
            <Controller
              rules={{ required: true }}
              control={control}
              name="Attach Job Description"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel value="y" control={<Radio />} label="Yes" />
                  <FormControlLabel value="n" control={<Radio />} label="No" />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            height: "auto",
            // marginTop: "10px",
            //paddingLeft: "50px",
            //paddingRight: "50px",
            //boxShadow: "10px 11px 22px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Stack direction="row" gap={5} sx={{ width: "100%" }}>
            <Stack direction={"column"} gap={2} width={"50%"} mt={"20px"}>
              {/* BOT SECTION  */}
              <Box
                width={"100%"}
                height={"auto"}
                sx={{ border: "0px solid green" }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    marginBottom: "10px",
                    // fontWeight: "700",
                    // fontFamily: "Roboto",
                    // color: "#151515",
                  }}
                >
                  Bot Section
                </Typography>
                {/* <BotSection /> */}
                <Box
                  sx={{
                    width: "auto",
                    // height: "126px",
                    //padding: "12px 10px 11px 9px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "7px",
                    //  border: "1px solid red",
                    //  padding:"20px"
                    // backgroundColor: "white",
                  }}
                >
                  <Stack direction="column" sx={{ border: "0px solid blue" }}>
                    {/* /Bot Interpreted */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        border: "0px solid red",
                      }}
                    >
                      {/* TODO: */}
                      {botInterpretedWatch?.length > 0 && (
                          <InfoBox message={getMasterTooltipMessage("botInterpreted",botInterpretedWatch)} />
                        )}
                      <FormControl
                        variant="outlined"
                        style={{ marginTop: 1, minWidth: 150 }}
                        size="small"
                        fullWidth
                        error={Boolean(errors?.["Bot Interpreted"])}
                      >
                        {/* <InputLabel>Bot Interpreted*</InputLabel> */}
                        <InputLabel>
                          <ReuseableLabel label={"Bot Interpreted"} />
                        </InputLabel>
                        <Controller
                          name="Bot Interpreted"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Please select an option" }}
                          render={({ field }) => (
                            <>
                              <Select
                                {...field}
                                label="Bot Interpreted"
                                id="demo-simple-select-required"
                                labelId="demo-simple-select-required-label"
                                defaultValue="n"
                                onChange={(e) => {
                                  handleBotInterpreted(e);
                                  field.onChange(e);
                                }}
                                error={Boolean(errors?.["Bot Interpreted"])}
                              >
                                <MenuItem value="">Select an option</MenuItem>
                                <MenuItem value="y">Yes</MenuItem>
                                <MenuItem value="n">No</MenuItem>
                                <MenuItem value="c">Custom</MenuItem>
                                <MenuItem value="g">ChatGpt</MenuItem>
                                <MenuItem value="v">Score</MenuItem>
                              </Select>
                              {errors?.["Bot Interpreted"] && (
                                <FormHelperText error>
                                  {errors?.["Bot Interpreted"].message}
                                </FormHelperText>
                              )}
                            </>
                          )}
                        />
                      </FormControl>
                    </Box>
                    {/* TODO:  */}
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                    >
                      {botNameWatch?.length > 0 && <InfoBox message={getMasterTooltipMessage("botName",botNameWatch)} />}
                      <FormControl
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: 11, minWidth: 150 }}
                        size="small"
                        error={Boolean(errors?.["Bot Name"])}
                      >
                        {/* Bot Name Select Input */}
                        <InputLabel id="demo-simple-select-required-label">
                          {botInterpretedWatch === "n" ||
                          botInterpretedWatch === "" ? (
                            "Bot Name"
                          ) : (
                            <ReuseableLabel label={"Bot Name"} />
                          )}
                        </InputLabel>
                        <Controller
                          name="Bot Name"
                          control={control}
                          defaultValue=""
                          // rules={{ required: "Please select an option" }}
                          render={({ field }) => (
                            <>
                              <Select
                                {...field}
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                label="Bot Name"
                                defaultValue=""
                                disabled={botNameDisabled ? true : false}
                                style={{ height: "43px" }}
                                size="small"
                                error={Boolean(errors?.["Bot Name"])}
                                //   style={style}
                              >
                                <MenuItem value={""}>
                                  Please select an option
                                </MenuItem>
                                <MenuItem value={"yesOrNo"}>yesOrNo</MenuItem>
                                <MenuItem value={"checkNumeric#years"}>
                                  checkNumeric#years
                                </MenuItem>
                                <MenuItem value={"income"}>income</MenuItem>
                                <MenuItem value={"noticePeriod"}>
                                  noticePeriod
                                </MenuItem>
                                <MenuItem value={"checkGptResponse"}>
                                  checkGptResponse
                                </MenuItem>
                                <MenuItem value={"voiceScore"}>
                                  Communication Score
                                </MenuItem>
                              </Select>
                              {errors?.["Bot Name"] && (
                                <FormHelperText error>
                                  {errors?.["Bot Name"].message}
                                </FormHelperText>
                              )}
                            </>
                          )}
                        />
                      </FormControl>
                    </Box>
                    <FormControl
                      variant="outlined"
                      style={{ marginTop: 11, minWidth: 150 }}
                      size="small"
                      error={Boolean(errors.Path)}
                    >
                      {/* Path Select Input */}
                      <InputLabel id="demo-simple-select-required-label">
                        {/* Path* */}
                        <ReuseableLabel label={"Path"} />
                      </InputLabel>
                      <Controller
                        name="Path"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Please select an option" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="demo-simple-select-required-label"
                              id="demo-simple-select-required"
                              label="Path"
                              defaultValue=""
                              style={{ height: "43px" }}
                              error={Boolean(errors.Path)}
                            >
                              <MenuItem value={""}>
                                Please select an option
                              </MenuItem>
                              <MenuItem value={"y"}>Yes</MenuItem>
                              <MenuItem value={"n"}>No</MenuItem>
                              <MenuItem value={"b"}>Both</MenuItem>
                            </Select>
                            {errors.Path && (
                              <FormHelperText error>
                                {errors.Path.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      style={{ marginTop: 11, minWidth: 150 }}
                      size="small"
                      error={Boolean(errors.Adaptive)}
                    >
                      {/* Adaptive Select Input */}
                      <InputLabel id="demo-simple-select-required-label">
                        {/* Adaptive * */}
                        <ReuseableLabel label={"Adaptive"} />
                      </InputLabel>
                      <Controller
                        name="Adaptive"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Please select an option" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="demo-simple-select-required-label"
                              id="demo-simple-select-required"
                              label="Adaptive"
                              defaultValue=""
                              style={{ height: "43px" }}
                              error={Boolean(errors.Adaptive)}
                              //   style={style}
                            >
                              <MenuItem value={""}>
                                Please select an option
                              </MenuItem>
                              <MenuItem value={"y"}>Yes</MenuItem>
                              <MenuItem value={"n"}>No</MenuItem>
                              {/* <MenuItem value={"b"}>Both</MenuItem> */}
                            </Select>
                            {errors.Adaptive && (
                              <FormHelperText error>
                                {errors.Adaptive.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>

                    <FormControl
                      variant="outlined"
                      style={{ marginTop: 11, minWidth: 150 }}
                      size="small"
                      error={Boolean(errors.yPath)}
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        YPath
                      </InputLabel>
                      <Controller
                        name="yPath"
                        control={control}
                        defaultValue=""
                        // rules={{ required: "Please select an option" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="demo-simple-select-required-label"
                              id="demo-simple-select-required"
                              label="YPath*"
                              defaultValue=""
                              style={{ height: "43px" }}
                              error={Boolean(errors.yPath)}
                            >
                              <MenuItem value={""}>
                                Please select an option
                              </MenuItem>
                              <MenuItem value={"0"}>0</MenuItem>
                              <MenuItem value={"1"}>1</MenuItem>
                            </Select>
                            {errors.yPath && (
                              <FormHelperText error>
                                {errors.yPath.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>

                    <FormControl
                      variant="outlined"
                      style={{ marginTop: 11, minWidth: 150 }}
                      size="small"
                      error={Boolean(errors.nPath)}
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        NPath
                      </InputLabel>
                      <Controller
                        name="nPath"
                        control={control}
                        defaultValue=""
                        // rules={{ required: "Please select an option" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="demo-simple-select-required-label"
                              id="demo-simple-select-required"
                              label="NPath"
                              defaultValue=""
                              style={{ height: "43px" }}
                              error={Boolean(errors.nPath)}
                            >
                              <MenuItem value={""}>
                                Please select an option
                              </MenuItem>
                              <MenuItem value={"0"}>0</MenuItem>
                              <MenuItem value={"1"}>1</MenuItem>
                            </Select>
                            {errors.nPath && (
                              <FormHelperText error>
                                {errors.nPath.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                  </Stack>
                </Box>
              </Box>
              {/* NEW SECTION  */}
              <Box sx={{ border: "0px solid red" }}>
                <Stack>
                  {/* MANDATORY QUESTION  */}
                  <FormControl
                    variant="outlined"
                    //style={{ minWidth: "45%" }}
                    size="small"
                    error={Boolean(errors.questionType)}
                  >
                    <InputLabel id="demo-simple-select-required-label">
                      {/* Question Type* */}
                      <ReuseableLabel label={"Question Type"} />
                    </InputLabel>
                    <Controller
                      name="questionType"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            label="Question Type*"
                            defaultValue=""
                            style={{ height: "43px" }}
                            error={Boolean(errors.questionType)}
                            //   style={style}
                          >
                            <MenuItem value={""}>
                              Please select an option
                            </MenuItem>
                            <MenuItem value={"Default Question"}>
                              Default Question
                            </MenuItem>
                            <MenuItem value={"Generic Question"}>
                              Generic Question
                            </MenuItem>
                            <MenuItem value={"Job Specific Question"}>
                              Job Specific Question
                            </MenuItem>
                            <MenuItem value={"POFU Question"}>
                              POFU Question
                            </MenuItem>
                            <MenuItem value={"Offer Question"}>
                              Offer Question
                            </MenuItem>
                            <MenuItem value={"Onboarding Question"}>
                              Onboarding Question
                            </MenuItem>
                            <MenuItem value={"BlueCollar Question"}>
                              BlueCollar Question
                            </MenuItem>
                          </Select>
                          {errors.questionType && (
                            <FormHelperText error>
                              {errors.questionType.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    style={{ marginTop: 11, minWidth: 150 }}
                    size="small"
                    error={Boolean(errors.mandatoryQuestion)}
                  >
                    <InputLabel id="demo-simple-select-required-label">
                      Mandatory Question
                    </InputLabel>
                    <Controller
                      name="mandatoryQuestion"
                      control={control}
                      defaultValue=""
                      // rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            labelId="mandatoryQuestion"
                            id="mandatoryQuestionId"
                            label="mandatoryQuestion"
                            defaultValue=""
                            style={{ height: "43px" }}
                            error={Boolean(errors.mandatoryQuestion)}
                          >
                            <MenuItem value={""}>
                              Please select an option
                            </MenuItem>
                            <MenuItem value={"yes"}>Yes</MenuItem>
                            <MenuItem value={"no"}>No</MenuItem>
                          </Select>
                          {errors.mandatoryQuestion && (
                            <FormHelperText error>
                              {errors.mandatoryQuestion.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                  {/* QUESTION EXPIRY  */}
                  <FormControl
                    variant="outlined"
                    style={{ marginTop: 11, minWidth: 150 }}
                    size="small"
                    error={Boolean(errors.questionExpiry)}
                  >
                    <InputLabel id="demo-simple-select-required-label">
                      {/* Question Expiry* */}
                      <ReuseableLabel label={"Question Expiry"} />
                    </InputLabel>
                    <Controller
                      name="questionExpiry"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            labelId="questionExpiry"
                            id="questionExpiryId"
                            label="Question Expiry*"
                            defaultValue=""
                            style={{ height: "43px" }}
                            error={Boolean(errors.questionExpiry)}
                          >
                            <MenuItem value={""}>
                              Please select an option
                            </MenuItem>
                            <MenuItem value={"never"}>Never</MenuItem>
                            <MenuItem value={"15"}>15 days</MenuItem>
                            <MenuItem value={"30"}>30 days</MenuItem>
                            <MenuItem value={"90"}>90 days</MenuItem>
                            <MenuItem value={"180"}>180 days</MenuItem>
                            <MenuItem value={"365"}>365 days</MenuItem>
                          </Select>
                          {errors.questionExpiry && (
                            <FormHelperText error>
                              {errors.questionExpiry.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                  {/* DOCUMENT TYPE  */}
                  {/* 
                   <FormControl
                    variant="outlined"
                    style={{ marginTop: 11, minWidth: 150 }}
                    size="small"
                    error={Boolean(errors.documentType)}
                  >
                    <InputLabel id="documentType">Document Type</InputLabel>
                    <Controller
                      name="documentType"
                      control={control}
                      defaultValue=""
                      // rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            labelId="documentType"
                            id="documentTypeId"
                            label="Document Type"
                            defaultValue=""
                            style={{ height: "43px" }}
                            error={Boolean(errors.documentType)}
                          >
                            <MenuItem value={""}>
                              Please select an option
                            </MenuItem>
                            <MenuItem value={"resume"}>Resume</MenuItem>
                            <MenuItem value={"coverLetter"}>
                              Cover Letter
                            </MenuItem>
                            <MenuItem value={"experienceLetters"}>
                              Experience Letters
                            </MenuItem>
                            <MenuItem value={"pancard"}>Pan Card</MenuItem>
                            <MenuItem value={"aadhar"}>Aadhar</MenuItem>
                            <MenuItem value={"passport"}>Passport</MenuItem>
                            <MenuItem value={"previousOffer"}>
                              Previous Offer
                            </MenuItem>
                            <MenuItem value={"educationalCertificates"}>
                              Educational Certificates
                            </MenuItem>
                            <MenuItem value={"certificates"}>
                              Certificates
                            </MenuItem>
                            <MenuItem value={"other"}>Other</MenuItem>
                          </Select>
                          {errors.documentType && (
                            <FormHelperText error>
                              {errors.documentType.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                */}
                </Stack>
              </Box>
            </Stack>
            {/* GENERAL SECTION  */}
            <Box width={"50%"} height={"97%"} marginTop={"20px"}>
              <Typography
                sx={{
                  fontSize: "18px",
                  marginBottom: "10px",
                  // fontWeight: "700",
                  // fontFamily: "Roboto",
                  // color: "#151515",
                }}
              >
                General Section
              </Typography>
              {/* <GeneralSection /> */}
              <Box>
                <Stack direction="column" spacing={1}>
                  <TextField
                    fullWidth
                    //multiline
                    variant="outlined"
                    //label="ack"
                    label="Acknowledgement"
                    type="text"
                    // placeholder="ack"
                    placeholder="Acknowledgement"
                    inputProps={{
                      style: {
                        //backgroundColor: "white",
                      },
                    }}
                    size="small"
                    // {...register("Ack", {
                    //   required: "Ack is Required",
                    // })}
                    {...register("Ack")}
                    error={Boolean(errors.Ack)}
                    helperText={errors.Ack?.message}
                  />
                  <TextField
                    fullWidth
                    //multiline
                    variant="outlined"
                    type="text"
                    placeholder="Default Value"
                    label={
                      isContainsDDollar ? (
                        <ReuseableLabel label="Default Value" />
                      ) : (
                        "Default Value"
                      )
                    }
                    defaultValue=""
                    inputProps={{
                      style: {
                        //backgroundColor: "white",
                      },
                    }}
                    size="small"
                    {...register("defaultValue", {
                      required: isContainsDDollar
                        ? "Default Value is Required"
                        : null,
                    })}
                    //{...register("defaultValue")}
                    error={Boolean(errors.defaultValue)}
                    helperText={errors.defaultValue?.message}
                  />
                  <Box
                    style={{
                      marginTop: 10,
                      minWidth: 150,
                      border: "1px solid #908E8E",
                      borderRadius: "7px",
                      height: "43px",
                    }}
                  >
                    <Stack direction={"row"} spacing={2} padding={1.2}>
                      <Typography
                        sx={{
                          paddingTop: "1px",
                          paddingLeft: "5px",
                          opacity: "0.7",
                          display: "flex",
                          gap: "2px",
                        }}
                      >
                        Weightage <span style={{ color: "red" }}> *</span>
                        {/* <ReuseableLabel label={"Weghtage"} /> */}
                      </Typography>
                      <PrettoSlider
                        valueLabelDisplay="auto"
                        aria-label="pretto slider"
                        sx={{ color: "blue", arialabelbgColor: "blue" }}
                        min={0.1}
                        step={0.1}
                        max={1}
                        style={{ width: "80%", marginLeft: "8%" }}
                        {...register("Weight", {
                          required: "Weight is Required",
                        })}
                        error={Boolean(errors.Weight)}
                        helperText={errors.Weight?.message}
                      />
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      border: "0px solid green",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {processVariableWatch?.length > 0 && (
                      <InfoBox message={getMasterTooltipMessage("processVariable","processVariable")} />
                    )}
                    <Controller
                      name="Process Variable"
                      // fullWidth
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          disablePortal
                          fullWidth
                          size="small"
                          filterSelectedOptions
                          //getOptionDisabled={(option) => option.disabled}
                          getOptionLabel={(option) => option} // Combine name and ID
                          //getOptionValue={(option) => option.toString()} // Use ID as the value
                          id="combo-box-demo"
                          onChange={(event, value) =>
                            field.onChange(value ? value : "")
                          } // Store the entire selected option
                          options={processVariables}
                          renderInput={(params) => (
                            <TextField
                              error={!!errors.masterCompanyName}
                              helperText={errors.masterCompanyName?.message}
                              id="Process Variable"
                              label="Process Variable"
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      border: "0px solid green",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <InfoBox message={getMasterTooltipMessage("scoringEngineFieldName","scoringEngineFieldName")} />
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Scoring Engine Field Name"
                      type="text"
                      placeholder="Scoring Engine Field Name"
                      inputProps={{
                        style: {
                          //backgroundColor: "white",
                        },
                      }}
                      size="small"
                      {...register("Scoring Engine Field Name")}
                      error={Boolean(errors?.["Scoring Engine Field Name"])}
                      helperText={
                        errors?.["Scoring Engine Field Name"]?.message
                      }
                    />
                  </Box>
                  {/* PROCESS VARIABLE */}
                  {/* <FormControl
                    variant="outlined"
                    style={{ marginTop: 11, minWidth: 150 }}
                    size="small"
                    
                  >
                    Adaptive Select Input
                    <InputLabel id="demo-simple-select-required-label">
                      Process Variable
                      <ReuseableLabel label={"Process Variable"} />
                    </InputLabel>
                    <Controller
                      name="Process Variable"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select process variable" }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            label="Process Variable"
                            defaultValue=""
                            style={{ height: "43px" }}
                            error={Boolean(errors?.["Process Variable"])}
                              // style={style}
                          >
                            <MenuItem value={""}>
                              Please select process variable
                            </MenuItem>
                            {processVariables.length > 0 &&
                              processVariables.map((processV, idx) => (
                                <MenuItem key={idx} value={processV}>
                                  {processV}
                                </MenuItem>
                              ))}

                            <MenuItem value={"b"}>Both</MenuItem>
                          </Select>
                          {errors?.["Process Variable"] && (
                            <FormHelperText error>
                              {errors?.["Process Variable"]?.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl> */}
                  <TextField
                    fullWidth
                    // multiline
                    variant="outlined"
                    type="text"
                    placeholder="Next Questin Prefix"
                    label="Next Question Prefix"
                    inputProps={{
                      style: {
                        // backgroundColor: "white",
                      },
                    }}
                    size="small"
                    // {...register("Next Question Prefix", {
                    //   required: "Next Question is Required",
                    // })}
                    {...register("Next Question Prefix")}
                    error={Boolean(errors?.["Next Question Prefix"])}
                    helperText={errors?.["Next Question Prefix"]?.message}
                  />
                  {/* ///////////////
                  <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    type="text"
                    name="nextQuestionPrefix"
                    placeholder="Next Question Prefix"
                    label="Next Question Prefix"
                    inputProps={{
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                    size="small"
                    {...register("nextQuestionPrefix", {
                      required: "Next Question Prefix is Required",
                    })}
                    error={Boolean(errors.nextQuestionPrefix)}
                    helperText={errors.nextQuestionPrefix?.message}
                  /> */}
                  <TextField
                    fullWidth
                    //multiline
                    variant="outlined"
                    type="text"
                    placeholder="Help Text"
                    label="Help Text"
                    inputProps={{
                      style: {
                        // backgroundColor: "white",
                      },
                    }}
                    size="small"
                    // {...register("Help Text", {
                    //   required: "HelpText is Required",
                    // })}
                    {...register("Help Text")}
                    error={Boolean(errors?.["Help Text"])}
                    helperText={errors?.["Help Text"]?.message}
                  />

                  {/* /************plain ****************/}
                  {/* <TextField
                    fullWidth
                    // multiline
                    variant="outlined"
                    type="text"
                    defaultValue={"plain"}
                    inputProps={{
                      style: {
                        //backgroundColor: "white",
                      },
                    }}
                    size="small"
                    {...register("Type", {
                      //required: "Button Type is Required",
                    })}
                    error={Boolean(errors.Type)}
                    helperText={errors.Type?.message}
                  /> */}

                  {/* /*************option1 ***************/}
                  {/* <TextField
                    fullWidth
                    //  multiline
                    variant="outlined"
                    type="text"
                    placeholder="Option1"
                    label="Option1"
                    inputProps={{
                      style: {
                        //backgroundColor: "white",
                      },
                    }}
                    size="small"
                    {...register("Option1")}
                  /> */}

                  {/* /************option 2**************** */}
                  {/* <TextField
                    fullWidth
                    //  multiline
                    variant="outlined"
                    type="text"
                    placeholder="Option2"
                    label="Option2"
                    inputProps={{
                      style: {
                        //backgroundColor: "white",
                      },
                    }}
                    size="small"
                    {...register("Option2")}
                  /> */}

                  {/* /*************option3 ***************/}
                  {/* <TextField
                    fullWidth
                    // multiline
                    variant="outlined"
                    type="text"
                    placeholder="Option3"
                    label="Option3"
                    inputProps={{
                      style: {
                        //backgroundColor: "white",
                      },
                    }}
                    size="small"
                    {...register("Option3")}
                  /> */}

                  {/* /***********Processing needed ****************/}
                  {/* <Stack direction="column" spacing={1}>
                    <FormControl
                      variant="outlined"
                      style={{ marginTop: 1, minWidth: 150 }}
                      size="small"
                      error={Boolean(errors?.["Processing Needed"])}
                    >
                      Bot Interpreted Select Input
                      <InputLabel id="demo-simple-select-required-label">
                        Processing Needed*
                        <ReuseableLabel label={"Processing Needed"} />
                      </InputLabel>
                      <Controller
                        name="Processing Needed"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Please select an option" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="demo-simple-select-required-label"
                              id="demo-simple-select-required"
                              label="Processing Needed"
                              defaultValue=""
                              error={Boolean(errors?.["Processing Needed"])}
                            >
                              <MenuItem value={""}>
                                Please select an option
                              </MenuItem>
                              <MenuItem value={"y"}>Yes</MenuItem>
                              <MenuItem value={"n"}>No</MenuItem>
                            </Select>
                            {errors?.["Processing Needed"] && (
                              <FormHelperText error>
                                {errors?.["Processing Needed"]?.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                  </Stack> */}

                  <Stack direction="column" spacing={1}>
                    <FormControl
                      variant="outlined"
                      style={{ marginTop: 1, minWidth: 150 }}
                      size="small"
                      error={Boolean(errors.expectDocument)}
                    >
                      {/* Bot Interpreted Select Input */}
                      <InputLabel id="demo-simple-select-required-label">
                        Expect Document
                      </InputLabel>
                      <Controller
                        name="expectDocument"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="demo-simple-select-required-label"
                              id="demo-simple-select-required"
                              label="Expect Document"
                              defaultValue=""
                            >
                              <MenuItem value={""}>
                                Please select an option
                              </MenuItem>
                              {/* <MenuItem value={"true"}>True</MenuItem>
                              <MenuItem value={"false"}>False</MenuItem> */}
                              <MenuItem value={"yes"}>Yes</MenuItem>
                              <MenuItem value={"no"}>No</MenuItem>
                            </Select>
                          </>
                        )}
                      />
                    </FormControl>
                    {/* DOCUMENT CATEGORIES */}
                    <FormControl
                      variant="outlined"
                      style={{ marginTop: 11, minWidth: 150 }}
                      size="small"
                      error={Boolean(errors.documentCategory)}
                    >
                      <InputLabel id="documentType">
                        Document Categories
                      </InputLabel>
                      <Controller
                        name="documentCategory"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              labelId="documentCategory"
                              id="documentCategoryId"
                              label="Document Categories"
                              defaultValue=""
                              style={{ height: "43px" }}
                              error={Boolean(errors.documentCategory)}
                              //value={selectedDocumentCategoryItem?.name}
                              disabled={
                                expectDocumentWatch !== "yes" ? true : false
                              }
                              //onChange={handleCategoryOnChange}
                            >
                              {/* <MenuItem value={""}>
                                Please select an option
                              </MenuItem> */}
                              {documentCategories.map((cate, idx) => {
                                return (
                                  <MenuItem key={idx} value={cate.code}>
                                    {cate.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {errors.documentCategory && (
                              <FormHelperText error>
                                {errors.documentCategory.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                    {/* DOCUMENT TYPE/CATEGORIES */}
                    <>
                      {documentSubCategories.length > 0 && (
                        <FormControl
                          variant="outlined"
                          style={{ marginTop: 11, minWidth: 150 }}
                          size="small"
                          error={Boolean(errors.documentType)}
                        >
                          <InputLabel id="documentType">
                            Document Type
                          </InputLabel>
                          <Controller
                            name="documentType"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Please select an option" }}
                            render={({ field }) => (
                              <>
                                <Select
                                  {...field}
                                  labelId="documentType"
                                  id="documentType"
                                  label="Document Type"
                                  defaultValue=""
                                  style={{ height: "43px" }}
                                  error={Boolean(errors.documentType)}
                                  disabled={
                                    expectDocumentWatch !== "yes" ? true : false
                                  }
                                  // onChange={handleCategoryOnChange}
                                >
                                  {/* <MenuItem value={""}>
                                Please select an option
                              </MenuItem> */}
                                  {documentSubCategories.map((scate, idx) => {
                                    return (
                                      <MenuItem key={idx} value={scate.name}>
                                        {scate.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                {errors.documentType && (
                                  <FormHelperText error>
                                    {errors.documentType.message}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          />
                        </FormControl>
                      )}
                    </>
                  </Stack>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    <InfoBox message={getMasterTooltipMessage("range","range")} />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "25px",
                      }}
                    >
                      <TextField
                        // fullWidth
                        //multiline
                        variant="outlined"
                        type="number"
                        label="Range Start"
                        name="rangeStart"
                        size="small"
                        placeholder="Range Start"
                        InputProps={{
                          sx: { borderRadius: "6px", width: "100%" },
                        }}
                        // style={{ width: "auto", height: "auto" }}
                        {...register("rangeStart")}
                        error={Boolean(errors.rangeStart)}
                        helperText={errors.rangeStart?.message}
                      />
                      {/* END RANGE  */}
                      <TextField
                        //fullWidth
                        //multiline
                        variant="outlined"
                        type="number"
                        label="Range End"
                        name="rangeEnd"
                        placeholder="Range End"
                        size="small"
                        InputProps={{
                          sx: { borderRadius: "6px", width: "100%" },
                        }}
                        // style={{ width: "auto", height: "auto" }}
                        {...register("rangeEnd")}
                        error={Boolean(errors.rangeEnd)}
                        helperText={errors.rangeEnd?.message}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Box>
        {/* ACTION BUTTON */}
        <Stack
          direction={"row"}
          gap={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginY: "20px",
          }}
        >
          <Button
            sx={{
              width: "140px",
              height: "41px",
              boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "8px",
              // fontFamily: "Poppins",
              fontSize: "15px",
              fontWeight: "500",
              // backgroundColor: "#1E0D61",
              // color: "white",
              backgroundColor: "primary.main",
              color: "secondary.main",
            }}
            type="submit"
          >
            Save
          </Button>

          <Button
            sx={{
              width: "140px",
              height: "41px",
              boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "8px",
              // fontFamily: "Poppins",
              fontSize: "15px",
              fontWeight: "500",
              // backgroundColor: "#FE9A25",
              // color: "white",
              backgroundColor: "secondary.main",
              color: "primary.main",
            }}
            onClick={() => navigate("/questions")}
          >
            Cancel
          </Button>
        </Stack>
      </form>
      <DeleteAlert
        open={open}
        onClose={handleClose}
        handleClose={handleClose}
        handleDelete={handleSimilarQuestionSubmit}
        header={`${isSameQuestionExist ? "Same" : "Similar"} Question`}
        message={`${
          isSameQuestionExist ? "Same" : "Similar"
        } question is available do you still want to create a new question?`}
        rightButton={"Create"}
      />
    </Box>
  );
};

export default Form;
