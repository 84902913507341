import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Modal,
  Select,
  MenuItem,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { style, initailState } from "../../Pages/HiringTabDashboard";
import { getProcessName } from "../../Redux/IFlowData/action";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReuseableLabel from "../ReusableComponents/ReuseableLabel";
import { accessTokenHeadersObject } from "../../utils/headers";
import {
  getHiringCompanyThroughData,
  getIflowHirngCompany,
  postHiringCompanyInFlow,
  updateIflowHiringCompany,
} from "../../Redux/HiringCompany/action";
import axios from "axios";

export const schema = yup.object().shape({
  name: yup
    .string()
    .required("Hiring company is required")
    .matches(
      /^(?=.*[A-Za-z&])[\w&,().:; -]*[A-Za-z&][\w&,().:; -]*$/,
      "Invalid hiring company & , ( ) . : ; - are allowed with alphabetic characters"
    )
    .test(
      "not only special characters",
      "not only special characters",
      (value) => {
        return /[^\W_]/.test(value);
      }
    )
    .nullable(),
  baseUrl: yup
    .string()
    .required("Base url is required")
    .matches(/^(http|https|www)/, "invalid base url format"),
  website: yup
    .string()
    .required("Website is required")
    .matches(/^(http|https|www)/, "invalid base url format"),
  processName: yup.string().required("Process name is required"),
  verifyToken: yup
    .string()
    .trim()
    .required("Verify token is required")
    .matches(
      /[A-Za-z& 0-9]/,
      "String must contain at least one alphabetic character"
    )
    .matches(/^[^\s]/, "String must not start with a space"),
  bucketName: yup
    .string()
    .required("Bucket name is required")
    .matches(
      /[A-Za-z& 0-9]/,
      "String must contain at least one alphabetic character"
    )
    .matches(/^[^\s]/, "String must not start with a space"),
  /*phone_number_internal: yup
  .string()
  .required("Phone number internal is required")
  .matches(/^\d+$/, "Phone number internal must contain only numbers"),
phone_number_id_internal: yup
  .string()
  .required("Phone number id internal is required")
  .matches(/^\d+$/, "Phone number id internal must contain only numbers"),*/
  sessionTableName: yup
    .string()
    .required("Session table name is required")
    .matches(
      /[A-Za-z& 0-9]/,
      "String must contain at least one alphabetic character"
    )
    .matches(/^[^\s]/, "String must not start with a space"),
  // waToken: yup.string().required("WA token is required"),
});

const EditHiringCompanyModal = ({
  open,
  setOpen,
  handleOnclickEditHiringCompany,
  data,
  setAlert,
  setAlertStatus,
  setalertcontent,
}) => {
  //   console.log(initailState);
  const handleClose = () => setOpen(false);
  //   console.log(data)
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initailState,
    resolver: yupResolver(schema),
  });
  const filter = createFilterOptions();
  const [valuex, setValuex] = useState(null);
  const [iflowCompanyData, setIflowCompanyData] = useState("");
  const [iflowCompanyDataId, setIflowCompanyDataId] = useState("");

  // console.log("iflowCompanyData",iflowCompanyData)
  // console.log("valueX", valuex);
  const processNameData = useSelector(
    (state) => state.iflowDataReducer.processName
  );

  const iflowHiringcompanyData = useSelector(
    (state) => state.hiringCompanyReducer.iflowHiringCompany
  );
  const iflowCompanyDetailsById = useSelector(
    (state) => state.hiringCompanyReducer.idFlowHiringCompany
  );
  const hiringCompanyName = watch("name");

  const bucketName = watch("bucketName");
  const hiringCompanyName_bucketname = hiringCompanyName + "-" + bucketName;
  // hiringCompanyName_bucketname.toLowerCase();

  const sessionTableName = watch("sessionTableName");
  const hiringCompanyName_sessionTableName =
    hiringCompanyName + "-" + sessionTableName;
  // hiringCompanyName_sessionTableName.toLowerCase();

  const processName = watch("processName");
  const selectedProcessNameObject = processNameData.filter(
    (el) => el.processname === processName
  );
  // console.log(processNameData)
  // console.log("selectedProcessNameObject", selectedProcessNameObject);
  const processAppURL =
    selectedProcessNameObject[0]?.processDef?.emailConfiguration?.appurl;
  useEffect(() => {
    dispatch(getIflowHirngCompany());
    dispatch(getProcessName());
    dispatch(getHiringCompanyThroughData(data?.iFlowCompanyId));
    reset(data);
  }, [open]);

  // console.log("datatata",data.name)

  useEffect(() => {
    setValue("baseUrl", processAppURL);
  }, [setValue, processAppURL]);

  const handleiFlowData = (valuex) => {
    dispatch(
      updateIflowHiringCompany(data.iFlowCompanyId,{
        resource: {
          ...iflowCompanyDetailsById,
          name:valuex,
          processName: "Initiation Process Pre-Screening",
        },
      })
    )
    .then((res) => {
        if (res.type === "UPDATE_IFLOWHIRINGCOMPANY_SUCCESS") {
          dispatch(getHiringCompanyThroughData(res?.payload.data))
            .then((res) => {
              setIflowCompanyData(res?.idFlowHiringCompany?.code);
              setIflowCompanyDataId(res?.idFlowHiringCompany?.id);
            })
            .catch((err) => {
              console.log(err);
            });
        }else{
        setValuex("")
        setAlert(true);
        setAlertStatus(false);
        setalertcontent(res.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const onSubmit = (editData) => {
    processNameData.forEach((val, key) => {
      if (val.processname == editData.processName) {
        editData.processNameIndex = key;
      }
    });
    editData.bucketName = hiringCompanyName_bucketname.toLowerCase();
    editData.sessionTableName =
      hiringCompanyName_sessionTableName.toLowerCase();
    const allProcess = processNameData[Number(editData.processNameIndex)];
    const allProcessRequireObject = {
      processName: allProcess?.processname,
      description: allProcess?.description,
      prefix: allProcess?.prefix,
      processlabel: allProcess?.processlabel,
      processkey: allProcess?.processkey,
    };
    
    const finalObjectData = { ...editData, ...allProcessRequireObject,iFlowCompanyId:iflowCompanyDataId?iflowCompanyDataId:valuex?.id };
    // axios
    //   .get(`https://pilot-dev.reca.ai/compapi/companies/${data.CompanyId}`)
    //   .then((res) => {
    //     console.log("HELLO SIR",res)
    //   }).catch((err)=>{
    //     console.log(err)
    //   });
    handleOnclickEditHiringCompany(finalObjectData);
    // if (data.name !== editData.name) {
    //   dispatch(getHiringCompanyThroughData(data.CompanyId))
    //     .then((res) => {
    //       dispatch(
    //         updateIflowHiringCompany(res.idFlowHiringCompany.id, {
    //           resource: { ...res.idFlowHiringCompany, name: editData.name },
    //         })
    //       )
    //         .then((res) => {
    //           // if (res.payload.status === true) {
    //             // handleOnclickEditHiringCompany(finalObjectData);
    //           // }else{
    //           //   setAlert(true);
    //           //   setAlertStatus(false);
    //           //   setalertcontent("OHHH MAA GO KICHI BHUL ACCHE");
    //           // }              
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         })
    //         .finally(()=>{
    //           handleOnclickEditHiringCompany(finalObjectData);
    //         });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // } else {
    //   handleOnclickEditHiringCompany(finalObjectData);
    // }
  };

  return (
    <Box>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography
                sx={{
                  textAlign: "center",
                  gap: "15px",
                  border: "0px solid blue",
                  fontSize: "30px",
                  fontWeight: 600,
                  //color: "#2B2A2C",
                  color: "primary.darkText",
                }}
              >
                Edit Hiring Company
              </Typography>
              <Box mt={"20px"}>
                <Stack direction={"row"} gap={2}>
                  {/* <TextField
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name="name"
                    label={<ReuseableLabel label={"Hiring Company"} />}
                    {...register("name")}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  /> */}
                  <Controller
                    name="name"
                    control={control}
                    // defaultValue={null}
                    // rules={{ required: "Please select an option" }} // Validation rule
                    render={({ field }) => (
                      <Autocomplete
                        sx={{ width: "50%" }}
                        // value={valuex}
                        defaultValue={data?.name}
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            setValuex({
                              name: newValue,
                            });
                          } else if (newValue && newValue.inputValue) {
                            handleiFlowData(newValue.inputValue);
                            // Create a new value from the user input
                            setValuex({
                              name: newValue.inputValue,
                            });
                          } else {
                            setValuex(newValue);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const { inputValue } = params;

                          const uniqueNames = new Set();

                          const filtered = options.filter((option) => {
                            const inputLowerCase = inputValue.toLowerCase();
                            const optionNameLowerCase =
                              option.name.toLowerCase();

                            if (uniqueNames.has(optionNameLowerCase)) {
                              return false; // Skip this option if the name is a duplicate
                            }
                            uniqueNames.add(optionNameLowerCase);

                            return true;
                          });

                          const matchedOptions = filtered.filter((option) =>
                            option.name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          );

                          if (
                            inputValue !== "" &&
                            !uniqueNames.has(inputValue.toLowerCase())
                          ) {
                            matchedOptions.push({
                              inputValue,
                              name: `Edit "${inputValue}"`,
                            });
                          }

                          return matchedOptions;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={iflowHiringcompanyData}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.name;
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>{option.name}</li>
                        )}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=<ReuseableLabel label={"Hiring Company"}/>
                            {...register("name")}
                            error={Boolean(errors.name)}
                            helperText={errors.name?.message}
                          />
                        )}
                      />
                    )}
                  />
                  <IconButton
                    onClick={handleClose}
                    sx={{ position: "absolute", top: "0", right: "0" }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name="website"
                    label={<ReuseableLabel label={"Company Website"} />}
                    {...register("website")}
                    error={Boolean(errors.website)}
                    helperText={errors.website?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                </Stack>
                <Stack direction={"row"} gap={2} mt={"12px"}>
                  <FormControl
                    variant="outlined"
                    style={{ marginTop: 1, minWidth: 150 }}
                    size="md"
                    fullWidth
                  >
                    <InputLabel>
                      <ReuseableLabel label={"Process Name"} />
                    </InputLabel>
                    <Controller
                      name="processName"
                      control={control}
                      rules={{ required: "Please select an option" }}
                      value="Hello" // Set your default value here
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            label={<ReuseableLabel label={"Process Name"} />}
                            id="demo-simple-select-required"
                            labelId="demo-simple-select-required-label"
                            error={Boolean(errors.processName)}
                            defaultValue={data.processName}
                          >
                            {/* <MenuItem value={data.processName}>{data.processName}</MenuItem> */}
                            {processNameData.map((el, index) => {
                              return (
                                <MenuItem value={el.processname} key={index}>
                                  {el.processname}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {errors.processName && (
                            <FormHelperText error>
                              {errors.processName.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack direction={"row"} gap={2} mt={"12px"}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name="baseUrl"
                    label={<ReuseableLabel label={"Base URL"} />}
                    {...register("baseUrl")}
                    // setValue={
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors.baseUrl)}
                    helperText={errors.baseUrl?.message}
                    // style={style}

                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name="verifyToken"
                    label={<ReuseableLabel label={"Verify Token"} />}
                    {...register("verifyToken")}
                    error={Boolean(errors.verifyToken)}
                    helperText={errors.verifyToken?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                </Stack>
                <Stack direction={"row"} gap={2} mt={"12px"}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name="bucketName"
                    label={<ReuseableLabel label={"Bucket Name"} />}
                    {...register("bucketName")}
                    error={Boolean(errors.bucketName)}
                    helperText={errors.bucketName?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                  {/*<TextField
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name="phone_number_internal"
                    label="Phone Number Internal*"
                    {...register("phone_number_internal")}
                    error={Boolean(errors.phone_number_internal)}
                    helperText={errors.phone_number_internal?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />*/}
                </Stack>
                <Stack direction={"row"} gap={2} mt={"12px"}>
                  {/*<TextField
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name="phone_number_id_internal"
                    label="Phone Numbet Id Internal*"
                    {...register("phone_number_id_internal")}
                    error={Boolean(errors.phone_number_id_internal)}
                    helperText={errors.phone_number_id_internal?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />*/}
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name="sessionTableName"
                    label={<ReuseableLabel label={"Session Table Name"} />}
                    {...register("sessionTableName")}
                    error={Boolean(errors.sessionTableName)}
                    helperText={errors.sessionTableName?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                </Stack>
                <Stack direction={"row"} gap={2} mt={"12px"}>
                  <TextField
                    multiline
                    rows={2}
                    sx={{ width: "100%" }}
                    id="filled-multiline-flexible"
                    name="waToken"
                    label="WhatsApp Token"
                    {...register("waToken")}
                    error={Boolean(errors.waToken)}
                    helperText={errors.waToken?.message}
                    // style={style}
                    InputProps={{ sx: { borderRadius: "6px" } }}
                  />
                </Stack>
              </Box>
              <Stack direction={"row"} gap={2} mt={"12px"}>
                <TextField
                  sx={{ width: "100%" }}
                  id="filled-multiline-flexible"
                  name=""
                  label="LinkedIn Page"
                  {...register("linkedinPage")}
                  // error={Boolean(errors.baseUrl)}
                  // helperText={errors.baseUrl?.message}
                  //  style={style}
                  InputProps={{ sx: { borderRadius: "6px" } }}
                />
                <TextField
                  sx={{ width: "100%" }}
                  id="filled-multiline-flexible"
                  name=""
                  label="Instagram Page"
                  {...register("careerPage")}
                  // error={Boolean(errors.verifyToken)}
                  // helperText={errors.verifyToken?.message}
                  // style={style}
                  InputProps={{ sx: { borderRadius: "6px" } }}
                />
              </Stack>
              <Stack direction={"row"} gap={2} mt={"12px"}>
                <TextField
                  sx={{ width: "100%" }}
                  id="filled-multiline-flexible"
                  name=""
                  label="Career Page"
                  {...register("careerPage")}
                  // error={Boolean(errors.baseUrl)}
                  // helperText={errors.baseUrl?.message}
                  // style={style}
                  InputProps={{ sx: { borderRadius: "6px" } }}
                />
                <TextField
                  sx={{ width: "100%" }}
                  id="filled-multiline-flexible"
                  name=""
                  label="X Handle"
                  {...register("xHandle")}
                  // error={Boolean(errors.verifyToken)}
                  // helperText={errors.verifyToken?.message}
                  // style={style}
                  InputProps={{ sx: { borderRadius: "6px" } }}
                />
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"right"}
                spacing={2}
                sx={{ width: "100%" }}
                mt={"15px"}
              >
                <Button
                  sx={{
                    borderRadius: "6px",
                    textTransform: "none",
                    // background: "#1E0D61",
                    // color: "#fff",
                    backgroundColor: "primary.main",
                    color: "secondary.main",
                    "&:hover": { background: "#220986" },
                  }}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </Button>
                <Button
                  onClick={handleClose}
                  sx={{
                    borderRadius: "6px",
                    // background: "#FE9A25",
                    // color: "#fff",
                    backgroundColor: "secondary.main",
                    color: "primary.main",
                    textTransform: "none",
                    "&:hover": { background: "#e9983c" },
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default EditHiringCompanyModal;
