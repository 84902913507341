import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Button,
  IconButton,
  Checkbox,
  Typography,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  DataGrid,
  // GridPageChangeParams,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  // GridToolbar,
  // GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid";
// import { Delete, Edit } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./selectgridtable.css";

import { environmentDetails } from "../environment/environment";
import ApiAlert from "../Components/AlertBox/ApiAlert";
import { useDispatch } from "react-redux";
import {
  /*getQuestionIdData,*/ getQuestionsData,
} from "../Redux/Questions/action";
import { createNewOpeningActionFn } from "../Redux/QuestionPacakge/action";
import { useDebounce } from "../hooks/useDebounce";
import { useIsLoading } from "../hooks/useIsLoading";
import { columns } from "../constants/selectQuestionTab";
import { getJobRoleByIdData } from "../Redux/JobRole/action";
// import PeopleToNotifyRepeater from "./PeopleToNotifyRepeater";

import { getOpeningByFilterActionFn } from "../Redux/QuestionPacakge/action";

const apiUrl = environmentDetails.apiUrl;

const customToolbar = () => {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        mt: "5px",
        mb: "8px",
      }}
    >
      <GridToolbarColumnsButton
        sx={{
          color: "white",
          fontWeight: 400,
          // backgroundColor: "#ff9900",
          backgroundColor: "secondary.main",
          textTransform: "none",
          "&:hover": {
            // background: "#1e0d61",
            backgroundColor: "secondary.dark",
            opacity: "0.9",
          },
        }}
      />
      <GridToolbarFilterButton
        sx={{
          color: "white",
          fontWeight: 400,
          // backgroundColor: "#ff9900",
          backgroundColor: "secondary.main",
          textTransform: "none",
          "&:hover": {
            //background: "#1e0d61",
            backgroundColor: "secondary.dark",
            opacity: "0.9",
          },
        }}
      />
      <GridToolbarDensitySelector
        sx={{
          color: "white",
          fontWeight: 400,
          // backgroundColor: "#ff9900",
          backgroundColor: "secondary.main",
          textTransform: "none",
          "&:hover": {
            //background: "#1e0d61",
            backgroundColor: "secondary.dark",
            opacity: "0.9",
          },
        }}
      />
      <GridToolbarExport
        sx={{
          color: "white",
          fontWeight: 400,
          // backgroundColor: "#ff9900",
          backgroundColor: "secondary.main",
          textTransform: "none",
          "&:hover": {
            //background: "#1e0d61",
            backgroundColor: "secondary.dark",
            opacity: "0.9",
          },
        }}
      />
    </GridToolbarContainer>
  );
};
/*const customCheckbox =() =>{
  return(
    <Checkbox color="primary" sx={{borderRadius:"50%"}} />
  )
}*/
const SelectQuestion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50000,
    page: 1,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [prevQuestionData, setPrevQuestionData] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setalertcontent] = useState("");
  const [alertStatus, setAlertStatus] = useState(Boolean);
  const [searchVal, setSearchVal] = useState("");
  const [page, setPage] = useState(1);
  const { debouncedValue } = useDebounce(searchVal);
  const [jobRoleData, setJobRoleData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  // const [notify, setNotify] = useState([{ cname: "", cphone: "", email: "" }]);

  //console.log("selectedData", selectedData);
  // const x = { ...selectedData };
  // console.log(page,pageSize)
  const { openingId, jobRole, companyName } = useParams();
  const { isLoading } = useIsLoading();
  const [isSelectedListCall, setIsSelectedListCall] = useState(false);
  var companiesIdArr = [companyName];
  var companiesId = companiesIdArr[0].split(",");
  // console.log("Companies Id Arr", companiesIdArr);

  const columns2 = [
    {
      field: "confirmed",
      headerName: (
        <Checkbox
          color="secondary"
          checked={data.length === selectedData.length}
          onChange={handleSelectAllData}
        />
      ),
      width: 70,
      renderCell: (params) => {
        return <Checkbox checked={params.row?.confirmed} />;
      },
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "id",
      headerName: "Sl. #",
      width: 70,
      align: "right",
    },
    {
      field: "Question",
      headerName: "Question",
      width: 280,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "questionType",
      headerName: "Question Type",
      width: 180,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Weight",
      headerName: "Weightage",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "Ack",
      headerName: "Acknowledgement",
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "mandatoryQuestion",
      headerName: "Mandatory Requirement?",
      width: 150,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "defaultValue",
      headerName: "Default Value",
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "expectDocument",
      headerName: "Expect Document",
      width: 140,
      headerAlign: "center",
      align: "Left",
    },
    {
      field: "Next Question Prefix",
      headerName: "Next Question Prefix",
      width: 230,
      headerAlign: "left",
      align: "left",
    },
  ];
  const columnsSelected = [
    {
      field: "confirmed",
      headerName: (
        <Checkbox
          color="secondary"
          checked={true}
          onChange={handleSelectAllDataSelected}
        />
      ),
      width: 70,
      renderCell: (params) => {
        return <Checkbox checked={params.row?.confirmed} />;
      },
      sortable: false,
      disableColumnMenu: true,
    },
    // {
    //   field: "id",
    //   headerName: "Sl. #",
    //   width: 70,
    //   align: "right",
    // },
    {
      field: "Question",
      headerName: "Question",
      width: 280,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "questionType",
      headerName: "Question Type",
      width: 180,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Weight",
      headerName: "Weightage",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "Ack",
      headerName: "Acknowledgement",
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "mandatoryQuestion",
      headerName: "Mandatory Requirement?",
      width: 150,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "defaultValue",
      headerName: "Default Value",
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "expectDocument",
      headerName: "Expect Document",
      width: 140,
      headerAlign: "center",
      align: "Left",
    },
    {
      field: "Next Question Prefix",
      headerName: "Next Question Prefix",
      width: 230,
      headerAlign: "left",
      align: "left",
    },
  ];

  const getQuestionsDataFn = () => {
    return dispatch(
      getQuestionsData(
        paginationModel.page,
        paginationModel.pageSize,
        debouncedValue
      )
    );
  };

  function handleSelectAllData() {
    if (data.length === selectedData.length) {
      let allUnConfirmedData = data.map((row) => {
        return { ...row, confirmed: false };
      });
      setData(allUnConfirmedData);
      setSelectedData([]);
    } else {
      let allConfirmedData = data.map((row) => {
        return { ...row, confirmed: true };
      });
      setData(allConfirmedData);
      setSelectedData(allConfirmedData);
    }
  }
  function handleSelectAllDataSelected() {
    let allUnConfirmedData = data.map((row) => {
      return { ...row, confirmed: false };
    });
    setData(allUnConfirmedData);
    setSelectedData([]);
  }
  useEffect(() => {
    ///getJobRoleById;
    dispatch(getJobRoleByIdData(jobRole)).then((res) => {
      if (res.type === "GET_JOBROLE_BY_ID_SUCCESS") {
        setJobRoleData(res.payload.data);
      }
    });
    //dispatch(getQuestionsData(page,pageSize,debouncedValue)).then((res) => {
    getQuestionsDataFn().then((res) => {
      if (res.type === "GET_QUESTIONS_SUCCESS") {
        // console.log("master question list first response--> ", res);
        setData(
          res.payload
            .filter(
              (obj) =>
                obj.questionStatus &&
                (obj.questionType === "Job Specific Question" ||
                  obj.questionType === "Generic Question" ||
                  obj.questionType === "Default Question" ||
                  obj.questionType === "POFU Question" ||
                  obj.questionType === "Offer Question" ||
                  obj.questionType === "Onboarding Question" ||
                  obj.questionType === "BlueCollar Question"
                )
            )
            .map((row, idx) => {
              return {
                ...row,
                id:
                  (paginationModel.page - 1) * paginationModel.pageSize +
                  idx +
                  1,
                rowId: idx,
                confirmed: false,
              };
            })
        );
        setTotalRows(res.datalength);
      } else {
        console.log("Master question response err: ", res);
      }
    });
  }, [debouncedValue]);
  // console.log("question Data: ", data);

  const handleSelectData = async () => {
    let data = selectedData.map((q) => {
      return { ...q, id: q.qId };
    });
    //IF SELECTED DATA IS EMPTY DON'T EXECUTE POST REQUEST SIMPLY RETURN FUNCTION.
    // if (!selectedData.length) return;
    // let selectedData = data.filter((data) => data.confirmed);
    dispatch(
      createNewOpeningActionFn(openingId, {
        jobRoleId: jobRole,
        companyIds: companiesId,
        selectedQuestionList: data,
        //peopleToBeNotified: notify
      })
    ).then((res) => {
      if (res.type === `CREATE_NEW_OPENING_SUCCESS`) {
        navigate(`/selected_question/${jobRole}/${companiesId[0]}`);
      } else {
        setAlert(true);
        setAlertStatus(false);
        setalertcontent("Question already exist in campaign");
      }
    });
    return;
    if (openingId === "null") {
      axios
        .post(`${apiUrl}/questionnaire/save-opening/`, {
          jobRoleId: jobRole,
          companyIds: companiesId,
          selectedQuestionList: selectedData,
        })
        .then((res) => {
          setTimeout(() => {
            navigate("/selected_question");
          }, 3000);
        })
        .catch((err) => {
          setAlert(true);
          setAlertStatus(false);
          setalertcontent("Question already exist in campaign");
          console.log(err);
        });
    } else {
      axios
        .post(`${apiUrl}/questionnaire/save-opening/${openingId}`, selectedData)
        .then((res) => {
          navigate("/selected_question");
        })
        .catch((err) => {
          setAlert(true);
          setAlertStatus(false);
          setalertcontent("Question already exist in campaign");
          console.log(err);
        });
    }
  };

  const handleSelectionChange = (selectedIds) => {
    // console.log("HANDLE ROW SELECTION CHANGED==> WITH ROW ID==> ",selectedIds);
    if (!selectedIds.length) return;
    let confirmedRows = data.map((row) => {
      return row.id === selectedIds[0]
        ? { ...row, confirmed: !row.confirmed }
        : row;
    });
    let confirmedFilteredRows = filteredData.map((row) => {
      return row.id === selectedIds[0]
        ? { ...row, confirmed: !row.confirmed }
        : row;
    });
    setData(confirmedRows);
    setFilteredData(confirmedFilteredRows);
    /*
    let filteredData = data.filter((row) => selectedIds.includes(row.id));
    var i = 0;
    setSelectedData(filteredData);
    */

    let prevSelectedData = [...selectedData];
    let [clickedRow] = data.filter((data) => data.id === selectedIds[0]);
    let rowAlreadyPresent = prevSelectedData.filter(
      (row) => row.qId === clickedRow.qId
    );
    if (rowAlreadyPresent.length > 0) {
      let rowIndex = prevSelectedData.findIndex(
        (data) => data.qId === rowAlreadyPresent[0].qId
      );
      prevSelectedData.splice(rowIndex, 1);
    } else {
      if (clickedRow?.["Attach Job Description"] === "y") {
        clickedRow = {
          ...clickedRow,
          "Job Description": jobRoleData.desc ? jobRoleData.desc : "",
          jdImage: jobRoleData.image ? jobRoleData.image : "",
          confirmed: true,
        };
      } else {
        clickedRow = {
          ...clickedRow,
          "Job Description": "",
          jdImage: "",
          confirmed: true,
        };
      }
      prevSelectedData.push(clickedRow);
    }
    prevSelectedData.forEach((val, i) => {
      val["sort"] = i + 1;
    });
    setSelectedData(prevSelectedData);
  };

  const handleSelectionChange2 = (selectedRowId) => {
    let [clicked] = selectedData.filter((item) => item.id === selectedRowId[0]);
    let removeSelected = selectedData.filter(
      (item) => item.id !== selectedRowId[0]
    );
    let manupulatedData = data.map((item) => {
      return item?.qId === clicked?.qId ? { ...item, confirmed: false } : item;
    });
    setSelectedData(removeSelected);
    setData(manupulatedData);
  };
  const AlerthandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const recruitmentCompanySelect = companiesId[0];

  const getSelectedQuestion = async () => {
    setIsSelectedListCall(true);
    //console.log("selected questionList called with DATA-----> ",data)
    if (jobRole) {
      dispatch(
        getOpeningByFilterActionFn(jobRole, recruitmentCompanySelect)
      ).then((res) => {
        if (res.type === "GET_OPENING_BY_FILTER_SUCCESS") {
          // Sort Campaign Questions List
          let selectedQuestionList = res.payload.selectedQuestionList;
          let ccc = selectedQuestionList.map((item) => (item.confirmed = true));
          // console.log("ssssssssssssselected ",ccc);
          setSelectedData(selectedQuestionList);
          // console.log(questionDetails)
          selectedQuestionList.sort((a, b) => {
            return a.sort - b.sort;
          });
          // Shreya's code
          // console.log(data)
          // console.log("selected question list--> ",selectedQuestionList)
          let org = data?.map((item) => {
            for (var i = 0; i < selectedQuestionList.length; i++) {
              // console.log(`main qId ${item.qId}, $$$  selected question qId ${selectedQuestionList[i].qId}`)
              //  console.log("selected question list--> ",selectedQuestionList)
              if (selectedQuestionList[i].qId === item.qId) {
                item.confirmed = true;
              }
            }
            return item;
          });
          //console.log("selcted list------> ", selectedQuestionList);
          //console.log("manupulated master questions data----> ", org);
          setData(org);
          // setIsSelectedListCall(true);
          // Shreya's code
        } else {
          setPrevQuestionData([]);
          // setOpeningId(null);
        }
      });
    }
  };

  useEffect(() => {
    if (openingId !== null && isSelectedListCall === false && data.length > 0) {
      //console.log("get opening callled ")
      getSelectedQuestion();
    }
  }, [data]);

  const handleFilterChange = (e) => {
    let value = e.target.value;
    let filtered;
    //let filtered = data.filter((item)=>item.Question.includes(value));
    if (value) {
      filtered = data.filter((item) => {
        return item.Question.toLowerCase().includes(value.toLowerCase());
      });
    } else {
      filtered = [];
    }
    setFilteredData(filtered);
    setFilterValue(value);
  };

  const handleConfirmChange = (e) => {
    //console.log('ddddddddddddddddddddd, ',data);
    // let confirmedRows = data.map((row)=>{
    //   return row.rowId===e?{...row,confirmed:!row.confirmed}:row
    // })
    // setData(confirmedRows);
    //console.log("haffffffffffffffff-----> ", confirmedRows);
  };

  const CustomTextField = ({ value, onChange, ...otherProps }) => {
    return <TextField {...otherProps} value={value} onChange={onChange} />;
  };
  return (
    <>
      <Box sx={{ width: "auto" }}>
        {alert && (
          <ApiAlert
            open={alert}
            snackbaronClose={AlerthandleClose}
            alertonClose={AlerthandleClose}
            alertType={alertStatus ? "success" : "error"}
            Content={alertContent}
          />
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          //border: "2px solid blue",
        }}
      >
        {selectedData.length > 0 && (
          <>
            <Box
              sx={{
                height: "250px",
                maxHeight: "300px",
                width: "100%",
                border: "0px solid teal",
                marginBottom: "40px",
              }}
            >
              <Typography component={"h1"} variant="subtitle1">
                Selected Questions
              </Typography>
              <DataGrid
                rows={selectedData}
                columns={columnsSelected}
                experimentalFeatures={{ columnGrouping: true }}
                // selectionModel={3}
                onRowSelectionModelChange={handleSelectionChange2}
                hideFooter
                sx={{
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "primary.main",
                    color: "secondary.light",
                    fontSize: "14px",
                    height: "50px",
                    textWrap: "wrap",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "#fe9a25",
                  },
                  "& .MuiDataGrid-filterIcon": {
                    color: "#fe9a25",
                  },
                  "& .MuiDataGrid-menuIconButton": {
                    color: "#fe9a25",
                  },
                  "& .MuiDataGrid-toolbarContainer": {
                    textAlign: "center",
                  },
                  "& .MuiDataGrid-checkboxInput": {
                    color: "primary.main",
                  },
                }}
              />
            </Box>
            <Divider />
          </>
        )}
        <Box>
          <Typography component={"h1"} variant="subtitle1">
            Master Questions
          </Typography>
          <>
            <TextField
              sx={{ width: "40%" }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      {/* This Search Icon we used for Search Input Box */}
                      <SearchIcon style={{ color: "#CAC2C2" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "12px",
                  // height: "50px",
                  //backgroundColor: "#FFFFFF",
                  //padding: 2,
                },
              }}
              variant="outlined"
              type="text"
              placeholder={"Search question here"}
              //onChange={(e) => setSearchVal(e.target.value)}
              onChange={handleFilterChange} //We set the SetSearch Onchange function.
            />
          </>
          <Box
            sx={{
              height: "70vh",
              maxHeight: "70vh",
              width: "100%",
              mt: "10px",
              // height: "fit-content",
              // overflowY:"scroll",
              // width: "90vw",
              //border: "2px solid red",
            }}
          >
            <DataGrid
              rows={filterValue ? filteredData : data}
              columns={columns2}
              experimentalFeatures={{ columnGrouping: true }}
              // pagination
              // page={paginationModel.page -1}
              // pageSize={paginationModel.pageSize}
              // paginationMode="server"
              // rowCount={totalRows}
              // onPaginationModelChange={(params) => setPaginationModel((prev) => ({...prev, page: params.page + 1,pageSize:params.pageSize }))}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              //checkboxSelection
              selectionCountProps={{
                hideCount: true,
              }}
              rowReordering
              selectionModel={3}
              onRowSelectionModelChange={handleSelectionChange}
              slots={{
                toolbar: customToolbar,
              }}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  //backgroundColor: "rgba(255, 7, 0, 0.55)",
                  backgroundColor: "primary.main",
                  color: "secondary.light",
                  fontSize: "14px",
                  height: "50px",
                  textWrap: "wrap",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "#fe9a25",
                },
                "& .MuiDataGrid-filterIcon": {
                  color: "#fe9a25",
                },
                "& .MuiDataGrid-menuIconButton": {
                  color: "#fe9a25",
                },
                "& .MuiDataGrid-toolbarContainer": {
                  // backgroundColor: "#1e0d61",
                  textAlign: "center",
                },
                "& .MuiDataGrid-checkboxInput": {
                  color: "primary.main",
                },
                // "& .MuiDataGrid-toolbarFilterList":{
                //     backgroundColor:"blue"
                // },
              }}
              loading={isLoading}
            />
          </Box>
        </Box>

        {/* PEOPLE TO BE NOTIFIED SECTION  */}
        {/* <Box>
          <PeopleToNotifyRepeater formValues={notify} setFormValues={setNotify} />
        </Box> */}
        <Stack
          sx={{
            width: "100%",
            height: "fit-content",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginTop: "5px",
            //border: "1px solid blue",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "100%",
                height: "35px",
                //backgroundColor: "#1E0D61",
                backgroundColor: "primary.main",
                color: "white",
                "&:disabled": {
                  // background: "#eaeaea",
                  backgroundColor: "primary.dark",
                  color: "#c0c0c0",
                  cursor: "not-allowed",
                  pointerEvents: "all !important",
                },
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
              disabled={selectedData.length <= 0}
              onClick={handleSelectData}
            >
              Done
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                height: "35px",
                // backgroundColor: "#FE9A25",
                backgroundColor: "secondary.main",
                color: "white",
                "&:hover": {
                  backgroundColor: "secondary.light",
                },
              }}
              onClick={() => navigate("/selected_question")}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
        <Box sx={{ border: "0px solid green", height: "50px" }} />
      </Box>
    </>
  );
};

export default SelectQuestion;
