import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  Tooltip,
  Typography,
} from "@mui/material";
import { AccountCircle, Brightness6, Edit, Logout } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import ProfileModal from "../Profile/ProfileModal";
import { userLogoutActionFn } from "../../Redux/Auth/action";
import { useColorMode } from "../../hooks/useColorMode";
// import { useLocation } from "react-router-dom";

const ProfileTopBar = ({ toggleThemeMode, themeMode }) => {
  // const { pathname } = useLocation();
  const [userData, setUserData] = useState(null);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseUpdateProfile = () => {
    setUpdateProfile(false);
  };

  useEffect(() => {
    let userD = JSON.parse(window.localStorage.getItem("userLogin"));
    if (userD) {
      setUserData(userD.data);
    }
  }, []);

  const handleLogoutClick = () => {
    dispatch(userLogoutActionFn());
  };
  return (
    <Box
      sx={{
        height: "fit-content",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        boxSizing: "border-box",
        paddingRight: "0px",
        marginBottom: "15px",
        position: "sticky",
        gap: "10px",
        top: 0,
      }}
    >
      {/* <Box>
        <div id="google_translate_element"></div>
      </Box> */}
     <Tooltip title={themeMode==='light'?'Switch to Dark mode':'Switch to Light mode'} arrow placement="left">
      <IconButton onClick={toggleThemeMode} size="small" padding={0}>
        <Brightness6 />
      </IconButton>
     </Tooltip>
      <React.Fragment>        
        <Box
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
            border: "1px solid gray",
            borderRadius: "50px",
            padding: "3px",
          }}
        >  
          <Avatar src={userData?.companyLogo} variant="string" size="small" sx={{ width: 22, height: 22 }}>
            <AccountCircle />
          </Avatar>

          <Typography
            variant="subtitle1"
            sx={{
              color: "primary.mainText",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Welcome, {` ${userData?.fname}`}
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Box
            height={{
              height: "auto",
              width: "250px",
              boxSizing: "border-box",
              paddingLeft: "15px",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: "primary.darkText",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
              {userData?.fullname}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "primary.darkText",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
              {userData?.email}
            </Typography>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  // color: "#858383",
                  color: "primary.lightText",
                  marginBottom: "8px",
                }}
              >
                Roles
              </Typography>
              <Box
                sx={{
                  minHeight: "fit-content",
                  maxHeight: "170px",
                  overflowY: "scroll",

                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    backgroundColor: "rgba(32, 30, 32, 0.082)",
                    borderRadius: "5px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(54, 9, 71, 0.877)",
                    borderRadius: "5px",
                  },
                }}
              >
                {userData?.roles?.map((role, idx) => (
                  <Typography
                    key={idx}
                    variant="body2"
                    sx={{
                      fontSize: "13px",
                      color: "#858383",
                    }}
                  >
                    {role}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Divider />
            <Button
              onClick={() => setUpdateProfile(true)}
              component="label"
              variant="link"
              sx={{ marginTop: "8px" }}
              startIcon={<Edit />}
            >
              Update Profile
            </Button>
            <br />
            <Button
              component="label"
              sx={{ color: "red !important" }}
              onClick={handleLogoutClick}
              variant="link"
              startIcon={<Logout />}
            >
              Logout
            </Button>
          </Box>
        </Menu>
      </React.Fragment>
      <ProfileModal
        open={updateProfile}
        handleClose={handleCloseUpdateProfile}
      />
    </Box>
  );
};

export default ProfileTopBar;
