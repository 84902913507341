import React, { useEffect, useState } from "react";
import SearchFeild from "../Components/Table/SearchFeild";
import JobRoleTable from "../Components/Table/JobRoleTable";
import FileUpload from "react-material-file-upload";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Modal,
  Stack,
  TextField,
  Typography,
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  getSnackbarUtilityClass,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { environmentDetails } from "../environment/environment";
import { useDispatch, useSelector } from "react-redux";
import { getJobRoleData, postJobRoles } from "../Redux/JobRole/action";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CurrencyList from "currency-list";
import {
  getCountryData,
  getCountryName,
  getCityName,
} from "../Redux/Country/action";
import { useDebounce } from "../hooks/useDebounce";
import ApiAlert from "../Components/AlertBox/ApiAlert";
import ReuseableLabel from "../Components/ReusableComponents/ReuseableLabel";
import { CkEditorComponent } from "../Components/ReusableComponents/CkEditor";
import { privateAxiosInstance } from "../utils/axiosInstance";
import { accessTokenHeadersObject } from "../utils/headers";
import { string } from "yup";
import { UploadImage } from "../Components/ReusableComponents/UploadImage";
import axios from "axios";
import ATSJobDescripton from "./ATSJobDescripton";
import parse, { htmlToDOM } from "html-react-parser";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const apiUrl = environmentDetails.apiUrl;

const filter = createFilterOptions();

const options = {
  replace(domNode) {
    if (domNode.attribs && domNode.attribs.class === "remove") {
      return <></>;
    }
  },
};

const currencies = CurrencyList.getAll("en_US");
//console.log("xxxxxxxxxxxx",currencies)

const allCurrencies = [];

for (let key in currencies) {
  allCurrencies.push({
    id: Date.now().toString() + 116346461,
    ...currencies[key],
  });
}
//console.log("allCurrencies",allCurrencies)

const locationOption = [
  {
    id: "1",
    location: "Kolkata",
  },
  {
    id: "2",
    location: "Pune",
  },
  {
    id: "3",
    location: "Delhi",
  },
  {
    id: "4",
    location: "Odisha",
  },
  {
    id: "5",
    location: "Bangalore",
  },
  {
    id: "6",
    location: "Hyderabad",
  },
];

// initial state
export const initailState = {
  location: ["kolkata"],
  country: "",
  cities: [],
  // customCityarr: [],
  jobOpeningId: "",
  role: "",
  image: "",
  skill: "",
  desc: "",
  currency: "",
};
export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflow: "auto",
};

const JobTabDashboard = () => {
  //const classes = useStyles();
  const dispatch = useDispatch();
  const [dataATS, setDataATS] = useState(null);
  // const [active, setActive] = useState(2);
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = useState([]);
  const [currValue, setCurrValue] = useState("");
  const [jobOpeningId, setJobOpeningId] = useState("");
  // ----------custom city------------------------------
  // const [customCityarr, setCustomCityArr] = useState([]);
  // const [customCityele, setCustomCityEle] = useState("");
  // const [isDuplicateCCity, setIsDuplicateCCity] = useState(false);
  // const [isEmptyCustomCity, setIsEmptyCustomCity] = useState(false);
  //----------custom city-------------------------------
  const [isDuplicateSkill, setIsDuplicateSkill] = useState(false);
  const [isEmptySkill, setIsEmptySkill] = useState(false);
  const [isEmptyRole, setIsEmptyRole] = useState(false);
  const [isEmptyDesc, setIsEmptyDesc] = useState(false);
  const [isEmptyCurrency, setIsEmptyCurrency] = useState(false);
  const [isEmptyLocation, setIsEmptyLocation] = useState(false);
  const [isEmptyCountry, setIsEmptyCountry] = useState(false);
  const [isEmptyCity, setIsEmptyCity] = useState(false);
  const [isEmptyImageFile, setIsEmptyImageFile] = useState(false);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [image, setImage] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);
  const [currencyId, setCurrencyId] = useState("");
  const [role, setRole] = useState([]);
  const [desc, setDesc] = useState([]);
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [generateJDImgErr, setGenerateJDImgErr] = useState(false);
  const [jdWords, setJdWords] = useState(100);

  const loadingState = false;
  //============== PAGE ROWSPERPAGE STATES=================
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { debouncedValue } = useDebounce(search);
  const [updateData, setUpdateData] = useState(null);
  const [generateJobDescriptionError, setGenerateJobDescriptionError] =
    useState(false);

  ///country names
  const [countries, setCountries] = useState([]);
  // city names
  const [cities, setCities] = useState([]);

  useEffect(() => {
    dispatch(getCountryName()).then((res) => {
      let data = Array.from(res.payload);
      let cc = data?.map((i) => i.country);
      setCountries(cc);
    });
  }, []);

  useEffect(() => {
    if (selectedCountry != "") {
      dispatch(getCityName(selectedCountry)).then((res) => {
        if (res.payload) {
          setCities([...res?.payload?.data]);
        } else {
          setCities([""]);
        }
      });
    }
  }, [selectedCountry]);

  //Country Data
  const countryData = useSelector((state) => state.countryReducer.country);

  let indianCurrency;
  const x = countryData.map((el) => {
    if (el.nationality == "Indian") {
      indianCurrency = el;
    }
  });

  useEffect(() => {
    setCurrencyId(indianCurrency?.id);
  }, [indianCurrency]);

  // handle open & close modal functions.
  let countryObject = countryData?.find((x) => x.id === currencyId);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset(initailState);
    setOpen(false);
    setIsEmptyLocation(false);
    setIsEmptyCountry(false);
    setIsEmptyCity(false);
    setJobOpeningId("");
    // setIsEmptyCustomCity(false);
    setIsEmptySkill(false);
    setIsEmptyRole(false);
    setIsEmptyDesc(false);
    setActiveColor("");
    setIsEmptyImageFile(false);
    setIsEmptyCurrency(false);
    setValues([]);
    // setCustomCityArr([]);
    setSelectedLocation([]);
    setSelectedCountry("");
    setSelectedCity([]);
    setRole("");
    setDesc("");
    setCurrValue("");
    setYearsOfExperience("");
    // setCustomCityEle("");
    setCurrencyId(indianCurrency?.id);
    setValidImgtype(-1);
    setGenerateJDImgErr(false);
    setJdWords(100);
    setImage([]);
    setValidImgsize(-1);
    setDataATS(null);
  };
  // get job role;
  const jobRoleData = useSelector((state) => state.jobRoleReducer.jobRoles);
  // console.log("jobRoleData", jobRoleData);

  const [alert, setAlert] = useState(false);
  const [alertContent, setalertcontent] = useState("");
  const [activeColor, setActiveColor] = useState("");
  const [alertStatus, setAlertStatus] = useState(Boolean);

  const [validImgtype, setValidImgtype] = useState(-1);
  const [validImgsize, setValidImgsize] = useState(-1);

  useEffect(() => {
    // getJobRole();
    dispatch(getCountryData());
    // dispatch(getJobRoleData());
  }, []);
  // useEffect(() => {
  //    getJobRoleFn();
  //   //dispatch(getJobRoleData());
  // }, []);
  // formData.append("image",)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initailState,
  });

  // console.log("ats country", dataATS?.country);
  if (dataATS?.jobDescription) {
    const htmljd = JSON.stringify(dataATS?.jobDescription);
  }
  useEffect(() => {
    reset({
      country: dataATS?.country,
      cities: dataATS?.city,
      role: dataATS?.postingTitle,
      desc: dataATS?.jobDescription,
      yearsOfExperience: dataATS?.workExperience,
      skill: dataATS?.requiredSkills,
      jobOpeningId: dataATS?.jobOpeningId,
    });
    if (dataATS?.country) {
      setSelectedCountry({ country: dataATS?.country });
      setIsEmptyCountry(false);
    }
    if (dataATS?.city) {
      const cityarr = [];
      cityarr.push(dataATS?.city);
      setSelectedCity(cityarr);
      setIsEmptyCity(false);
    }
    if (dataATS?.postingTitle) {
      setRole(dataATS?.postingTitle);
      setIsEmptyRole(false);
    }
    if (dataATS?.jobDescription) {
      let jds = JSON.stringify(dataATS?.jobDescription)
        .slice(1, -1)
        .replace(/\\\\n/g, " ");
      // console.log("jds", jds.slice(1, -1));
      setDesc(jds);
      setIsEmptyDesc(false);
    }
    setYearsOfExperience(dataATS?.workExperience);
    if (dataATS?.requiredSkills) {
      setValues(dataATS.requiredSkills.split(","));
      setIsEmptySkill(false);
    }
    if (dataATS?.jobOpeningId) {
      // console.log("job opening id", dataATS?.jobOpeningId);
      setJobOpeningId(dataATS?.jobOpeningId);
    }
  }, [dataATS]);

  // add new job role form sumbit function;
  const onSubmit = (data) => {
    // if (selectedLocation?.length === 0) {
    //   setIsEmptyLocation(true);
    // }

    if (!selectedCountry) {
      setIsEmptyCountry(true);
    }

    if (selectedCity?.length === 0) {
      setIsEmptyCity(true);
    }

    // if (customCityarr?.length === 0) {
    //   setIsEmptyCustomCity(true);
    // }

    if (role?.length === 0) {
      setIsEmptyRole(true);
    }
    // if (desc === "") {
    //   setActiveColor("1px solid red");
    //   setIsEmptyDesc(true);
    // }
    if (values?.length === 0) {
      setIsEmptySkill(true);
    }

    if (image?.length === 0) {
      setIsEmptyImageFile(true);
    }
    if (currencyId === "") {
      setIsEmptyCurrency(true);
    }

    if (
      !role ||
      !selectedCountry ||
      // (selectedCity?.length === 0 && customCityarr?.length === 0) ||
      selectedCity.length === 0 ||
      values?.length === 0 ||
      image?.length === 0 ||
      !currencyId
    ) {
      console.log("not ready to post job role");
      return;
    }
    let imageFile = image[0];
    const formData = new FormData();
    formData.append("image", imageFile);

    setDesc(desc.replace(/<[^>]*>|&nbsp;|&amp;|[\r\n]|\\/g, ""))

    let newData = {
      ...data,
      role,
      skill: values?.join(","),
      // location: selectedLocation,
      country: selectedCountry.country,
      cities: selectedCity,
      // customCity: customCityarr?.join(","),
      currency: JSON.stringify(countryObject),
      desc: desc,
      jobOpeningId: jobOpeningId,
      words: jdWords,
    };
    Object.entries(newData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    // console.log("newdata", newData);
    // return;

    dispatch(postJobRoles(formData))
      .then((res) => {
        reset(initailState);
        setOpen(false);
        setAlert(true);
        setAlertStatus(res.payload.status);
        setalertcontent(res.payload.message);
        setValues([]);
        // setCustomCityArr([]);
        setDesc("");
        setDataATS("");
        setYearsOfExperience("");
        setRole("");
        setJobOpeningId("");
        setImage([]);
        setSelectedCountry("");
        setSelectedCity([]);
        setCurrencyId(indianCurrency?.id);
        dispatch(getJobRoleData());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleKeyUp = (e) => {
    let val = e.target.value.trim();
    let newValArr = values.map((i) => i.toLowerCase());
    // console.log("newvalarr", newValArr);
    // console.log("include", newValArr.includes(val.toLowerCase()));
    if (
      !isDuplicateSkill &&
      e.keyCode == 188 &&
      val !== "" &&
      !newValArr.includes(val.toLowerCase())
    ) {
      setValues((oldState) => [...oldState, val]);
      setCurrValue("");
    } else {
      setIsDuplicateSkill(true);
    }
  };

  // const handleKeyUpCCity = (e) => {
  //   let val = e.target.value.trim();
  //   if (!isDuplicateCCity && e.keyCode == 188 && val !== "") {
  //     setCustomCityArr((oldState) => [...oldState, val]);
  //     setCustomCityEle("");
  //   }
  // };
  const handleChange = (e) => {
    let val = e.target.value.trim().toLowerCase();
    const lowerCaseArr = values.map((str) => str.trim().toLowerCase());
    setIsDuplicateSkill(lowerCaseArr.includes(val));
    if (values?.length === 0) {
      setIsEmptySkill(true);
    } else {
      setIsEmptySkill(false);
    }
    setCurrValue(e.target.value.replace(/,/g, ""));
  };

  // const handleCustomCityChange = (e) => {
  //   let val = e.target.value.trim().toLowerCase();
  //   const lowerCaseArr = customCityarr.map((str) => str.trim().toLowerCase());
  //   setIsDuplicateCCity(lowerCaseArr.includes(val));
  //   if (customCityarr?.length === 0) {
  //     setIsEmptyCustomCity(true);
  //   } else {
  //     setIsEmptyCustomCity(false);
  //   }
  //   setCustomCityEle(e.target.value.replace(/,/g, ""));
  // };

  // remove skills chip function
  const handleDelete = (item, index) => {
    let arr = [...values];
    arr.splice(index, 1);
    //console.log(item);
    if (arr?.length === 0) {
      setIsEmptySkill(true);
    } else {
      setIsEmptySkill(false);
    }
    setValues(arr);
  };

  // const handleCCityDelete = (item, index) => {
  //   let arr = [...customCityarr];
  //   arr.splice(index, 1);
  //   //console.log(item);
  //   if (arr?.length === 0) {
  //     setIsEmptyCustomCity(true);
  //   } else {
  //     setIsEmptyCustomCity(false);
  //   }
  //   setCustomCityArr(arr);
  // };

  const rolehandler = (event) => {
    let val = event.target.value.trim();
    setRole(val);
    if (val === "") {
      setIsEmptyRole(true);
    } else {
      setIsEmptyRole(false);
    }
  };

  const jobOpeninghandler = (event) => {
    let val = event.target.value.trim();
    setJobOpeningId(val);
  };

  const deschandler = (event) => {
    let val = event.target.value.trim();
    setDesc(val);
    // if (val == "") {
    //   setActiveColor("1px solid red");
    //   setIsEmptyDesc(true);
    // } else {
    //   setActiveColor("");
    //   setIsEmptyDesc(false);
    // }
  };

  const handleCkEditorChange = (e, editor) => {
    let editorData = editor?.getData();    
    setDesc(editorData);
    if (!editorData) {
      if (editorData == "") {
        setActiveColor("1px solid red");
        setIsEmptyDesc(true);
      } else {
        setActiveColor("");
        setIsEmptyDesc(false);
      }
    }
  };
  // const locationhandleChange = (event, value, reason) => {
  //   setSelectedLocation(value.map((val) => val.location));
  //   if (value?.length === 0) {
  //     setIsEmptyLocation(true);
  //   } else {
  //     setIsEmptyLocation(false);
  //   }
  // };

  const countryhandleChange = (event, value) => {
    if (value == null) {
      setIsEmptyCountry(true);
      setSelectedCountry("");
      setIsEmptyCity(true);
      setSelectedCity([]);
    } else {
      setIsEmptyCountry(false);
      setIsEmptyCity(true);
      setSelectedCity([]);
      if (typeof value === "string") {
        setSelectedCountry({ country: value });
      } else if (value && value?.inputValue)
        setSelectedCountry({ country: value.inputValue });
      else setSelectedCountry(value);
    }
  };

  const cityhandleChange = (event, value) => {
    if (value == null) {
      setIsEmptyCity(true);
    } else {
      setSelectedCity(value.map((val) => val));
      setIsEmptyCity(false);
    }
  };

  const currencyOnChange = (event, value) => {
    // console.log(value);
    if (value == null) {
      setCurrencyId("");
      setIsEmptyCurrency(true);
    } else {
      setCurrencyId(value?.id);
      setIsEmptyCurrency(false);
    }
  };

  const handleFileUploaderOnChange = (data) => {
    if (data?.length === 0) {
      setIsEmptyImageFile(true);
      setImage(data);
    } else {
      const fileName = data[0].name;
      const extension = fileName.split(".").pop();
      const isSupported = ["png", "jpeg", "jpg"].includes(extension);
      const maxsize = 2 * 1024 * 1024;
      const filelimit = data[0].size < maxsize;
      if (isSupported && filelimit) {
        setValidImgtype(1);
        setValidImgsize(1);
        setIsEmptyImageFile(false);
        setImage(data);
      } else {
        if (!isSupported) setValidImgtype(0);
        if (!filelimit) setValidImgsize(0);
      }
    }
    setImage(data);
  };

  const atsData = (obj) => {
    setDataATS(obj);
  };

  // console.log("ats data", dataATS);

  const getJobRoleDataFn = () => {
    dispatch(getJobRoleData(page + 1, rowsPerPage, debouncedValue)).then(
      (res) => {
        //console.log("res: -- ",res?.payload?.dataLength);
        // if(res?.payload?.dataLength!==jobRoleData.dataLength){
        //      setPage(0);
        // }
      }
    );
  };
  //console.log("data: ",jobRoleData)
  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }
  }, [rowsPerPage, debouncedValue && jobRoleData?.dataLength !== undefined]);
  useEffect(() => {
    getJobRoleDataFn();
  }, [page, rowsPerPage, debouncedValue]);
  //  useEffect(()=>{},[]);

  const AlerthandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const handleGenerateJobDescription = () => {
    if (
      role?.length === 0 ||
      values?.length === 0 ||
      !yearsOfExperience ||
      selectedCountry?.length === 0 ||
      // (selectedCity?.length === 0 && customCityarr.length === 0)
      selectedCity.length === 0
    ) {
      setGenerateJobDescriptionError(true);
      let timerId = setTimeout(() => {
        setGenerateJobDescriptionError(false);
      }, 4000);
      // if(timerId){
      //   clearTimeout(timerId);
      // }
      return;
    }
    const bodyData = {
      jobRole: role,
      skills: values,
      words: jdWords,
      yearsOfExperience: yearsOfExperience,
      // location: selectedLocation,
      country: selectedCountry.country,
      cities: selectedCity,
      // customCity: customCityarr,
      currency: JSON.stringify(countryObject),
    };

    setRole(role);
    privateAxiosInstance
      .post(`/questionnaire/generateJobDescription`, bodyData, {
        headers: { ...accessTokenHeadersObject() },
      })
      .then((res) => {
        setDesc(res.data.content);
        setIsEmptyDesc(false);
        let newData = {
          role,
          skill: values.join(","),
          // location: selectedLocation,
          country: selectedCountry.country,
          cities: selectedCity,
          // customCity: customCityele.join(","),
          currency: JSON.stringify(countryObject),
          desc: res.data.content,
        };
        //reset(newData);
        setUpdateData(newData);
        setCurrencyId(countryObject?.id);
        setGenerateJDImgErr(false);
        //setDesc(res.data)
      })
      .catch((err) => {
        console.log("generate jd Err ", err);
      });
  };

  function removeQuotes(inputString) {
    const regex = /^"(.*)"$/;
    const match = regex.exec(inputString);
    if (match) {
      const contentWithinQuotes = match[1];
      return contentWithinQuotes;
    }
    return inputString;
  }

  useEffect(() => {
    if (updateData) {
      reset(updateData);
    }
  }, [updateData]);

  const generateJDImg = () => {
    if (desc.length <= 0) {
      setGenerateJDImgErr(true);
      return;
    }
    const data = {
      jobRole: role,
      yearsOfExperience: yearsOfExperience,
      skills: values,
      desc: removeQuotes(desc.replace(/<[^>]*>|[\r\n]|\\/g, "")),
    };
    privateAxiosInstance
      .post(
        "/questionnaire/generate-jdImage",
        { ...data },
        {
          responseType: "arraybuffer",
          headers: { ...accessTokenHeadersObject() },
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: "image/png" });
        const imageFile = new File([blob], `jdImage${Date.now()}.png`, {
          type: "image/png",
        });
        setImage([imageFile]);
        setIsEmptyImageFile(false);
        setValidImgtype(1);
        setValidImgsize(1);
      })
      .catch((err) => {
        console.log("Error:", err);
        setValidImgtype(0);
        setValidImgsize(0);
      });
  };

  return (
    <>
      {alert && (
        <ApiAlert
          open={alert}
          snackbaronClose={AlerthandleClose}
          alertonClose={AlerthandleClose}
          alertType={alertStatus ? "success" : "error"}
          Content={alertContent}
        />
      )}
      <Box style={{ width: "100%" }}>
        <SearchFeild
          buttonName="Add Job Role"
          placeholder="Search Job Role or Primary Skills"
          TextFieldWidth="30%"
          width="100%"
          large="0px"
          marginBottom={"15px"}
          Navigate={handleOpen}
          onChange={(e) => setSearch(e.target.value)}
          roleModule={"Job Role"}
        />
        <>
          {/* This is JObRole Table Components  */}
          <JobRoleTable
            jobRoleData={jobRoleData}
            search={search}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            getJobRoleDataFn={getJobRoleDataFn}
          />
        </>
        <Modal
          open={open}
          //onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                  border: "0px solid blue",
                }}
              >
                <IconButton
                  onClick={handleClose}
                  sx={{ position: "absolute", top: "0", right: "0" }}
                >
                  <CloseIcon />
                </IconButton>

                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: 600,
                    textAlign: "center",
                    // color: "#2B2A2C",
                    color: "primary.darkText",
                  }}
                >
                  Add Job Role
                </Typography>
                <ATSJobDescripton atsData={atsData} />

                <Stack direction={"row"} gap={2} mt={"3px"}>
                  <TextField
                    key="jobrole"
                    sx={{ width: "100%" }}
                    multiline
                    label={<ReuseableLabel label={"Job Role"} />}
                    variant="outlined"
                    defaultValue={role}
                    inputProps={{
                      maxLength: 30,
                      readOnly: dataATS && !isEmptyRole ? true : false,
                    }}
                    InputLabelProps={{ shrink: true }}
                    {...register("role", {})}
                    onKeyUp={rolehandler}
                    error={isEmptyRole}
                    helperText={errors.role?.message}
                  />
                  {isEmptyRole && (
                    <span
                      style={{
                        fontSize: "11px",
                        color: "red",
                      }}
                    >
                      Job Role is Required
                    </span>
                  )}

                  {/* Populated only if jd is pulled from ATS */}
                  <TextField
                    key="jobOpeningId"
                    sx={{ width: "100%" }}
                    label="Job Opening Id"
                    variant="outlined"
                    defaultValue={jobOpeningId}
                    inputProps={{
                      maxLength: 30,
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                    {...register("jobOpeningId", {})}
                    onKeyUp={jobOpeninghandler}
                  />
                </Stack>

                {/* <TextField
                  key="jobrole"
                  sx={{ width: "100%" }}
                  label={<ReuseableLabel label={"Job Role"} />}
                  variant="outlined"
                  defaultValue={role}
                  inputProps={{
                    maxLength: 30,
                    readOnly: dataATS && !isEmptyRole ? true : false,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...register("role", {})}
                  onKeyUp={rolehandler}
                  error={isEmptyRole}
                  helperText={errors.role?.message}
                />
                {isEmptyRole && (
                  <span
                    style={{
                      fontSize: "11px",
                      color: "red",
                    }}
                  >
                    Job Role is Required
                  </span>
                )}

                
                <TextField
                  key="jobOpeningId"
                  sx={{ width: "100%" }}
                  label="Job Opening Id"
                  variant="outlined"
                  defaultValue={jobOpeningId}
                  inputProps={{
                    maxLength: 30,
                    readOnly: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...register("jobOpeningId", {})}
                  onKeyUp={jobOpeninghandler}
                /> */}

                {/* Text */}
                <TextField
                  sx={{ width: "100%", mt: "-3px" }}
                  label="Years of Experience"
                  variant="outlined"
                  defaultValue={yearsOfExperience}
                  InputProps={{
                    readOnly: dataATS ? true : false,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...register("yearsOfExperience", {})}
                  onKeyUp={(e) => setYearsOfExperience(e.target.value)}
                />
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    minHeight: "70px",
                    border: `1px solid ${isEmptySkill ? "red" : "lightgray"}`,
                    width: "100%",
                    borderRadius: "3px",
                    marginY: "-3px",
                  }}
                >
                  {/* Skills Label  */}
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "-10px",
                      left: 10,
                      // background: "white",
                      backgroundColor: "primary.background",
                      color: `${isEmptySkill ? "red" : "#828282"}`,
                      fontSize: "12px",
                      marginX: "10px",
                    }}
                  >
                    <ReuseableLabel label={"Skills"} />
                  </Typography>
                  {/* CHIP BOX  */}
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                      marginY: "10px",
                    }}
                    className={"container"}
                  >
                    {values.map((item, index) => (
                      <Chip
                        size="small"
                        onDelete={() =>
                          !dataATS?.requiredSkills && handleDelete(item, index)
                        }
                        label={item}
                        key={index}
                      />
                    ))}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-end",
                      gap: "5px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      placeholder="Press comma(,) for skill"
                      value={currValue}
                      onChange={handleChange}
                      onKeyDown={handleKeyUp}
                      sx={{
                        width: "50%",
                        paddingLeft: "20px",
                        "& input::placeholder": {
                          fontSize: "11px",
                        },
                      }}
                      inputProps={{
                        readOnly: dataATS?.requiredSkills ? true : false,
                      }}
                      //error={isEmptySkill}
                      // helperText={`Skills required`}
                    />
                    {isDuplicateSkill && (
                      <span
                        style={{
                          fontSize: "11px",
                          color: "red",
                        }}
                      >
                        {`"${currValue}"`} already exist
                      </span>
                    )}
                    {isEmptySkill && (
                      <span
                        style={{
                          fontSize: "11px",
                          color: "red",
                        }}
                      >
                        Skill is Required
                      </span>
                    )}
                  </Box>
                </Box>

                {/* /*Location*/}
                {/* <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={locationOption}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.location}
                  onChange={locationhandleChange}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={
                          <CheckBoxIcon fontSize="small" color="blue" />
                        }
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.location}
                    </li>
                  )}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<ReuseableLabel label={"Location"} />}
                      placeholder="Location"
                      //   {...register("location", {
                      //   required: "Location is Required",
                      //  })}
                      error={isEmptyLocation}
                      helperText={isEmptyLocation && `Location is required`}
                    />
                  )}
                /> */}
                {/* Country */}
                <Stack direction={"row"} gap={2} mt={"-3px"}>
                  <Autocomplete
                    id="free-solo-with-text-demo"
                    options={
                      countries ? countries : ["chdfiovhe", "cndf", "cerfre"]
                    }
                    value={selectedCountry}
                    readOnly={dataATS && !isEmptyCountry}
                    onChange={countryhandleChange}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options?.some(
                        (option) => inputValue === option.country
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered?.push({
                          inputValue,
                          country: `${inputValue}`,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.country;
                    }}
                    renderOption={(props, option, idx) =>
                      option.inputValue ? (
                        <li {...props} key={option.iso3}>
                          {option.country}
                        </li>
                      ) : (
                        <li {...props} key={idx.index}>
                          {option}
                        </li>
                      )
                    }
                    style={{ width: "100%" }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={<ReuseableLabel label={"Country"} />}
                        placeholder="Country"
                        error={isEmptyCountry}
                        helperText={isEmptyCountry && `Country is required`}
                        // inputProps={{ readOnly: dataATS ? true : false }}
                      />
                    )}
                  />
                  {/* city */}
                  <Autocomplete
                    multiple
                    id="free-solo-with-text-demo"
                    options={cities}
                    value={selectedCity}
                    onChange={cityhandleChange}
                    style={{ width: "100%" }}
                    readOnly={dataATS && !isEmptyCity}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={<ReuseableLabel label={"City"} />}
                        placeholder="City"
                        error={isEmptyCity}
                        helperText={isEmptyCity && `City is required`}
                      />
                    )}
                  />
                </Stack>

                {/* /*
                {selectedCity.includes("other") && (
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      minHeight: "70px",
                      border: `1px solid ${
                        isEmptyCustomCity ? "red" : "lightgray"
                      }`,
                      width: "100%",
                      borderRadius: "3px",
                      marginY: "15px",
                    }}
                  >
                    
                    <Typography
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        left: 10,
                        // background: "white",
                        backgroundColor: "primary.background",
                        color: `${isEmptyCustomCity ? "red" : "#828282"}`,
                        fontSize: "12px",
                        marginX: "10px",
                      }}
                    >
                      <ReuseableLabel label={"Custom City"} />
                    </Typography>
                    
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                        marginY: "10px",
                      }}
                      className={"container"}
                    >
                      {customCityarr.map((item, index) => (
                        <Chip
                          size="small"
                          onDelete={() => handleCCityDelete(item, index)}
                          label={item}
                          key={index}
                        />
                      ))}
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-end",
                        gap: "5px",
                      }}
                    >
                      <TextField
                        variant="standard"
                        placeholder="Press comma(,) for city"
                        value={customCityele}
                        onChange={handleCustomCityChange}
                        onKeyDown={handleKeyUpCCity}
                        sx={{
                          width: "50%",
                          paddingLeft: "20px",
                          "& input::placeholder": {
                            fontSize: "11px",
                          },
                        }}
                        // error={isEmptyCity}
                        // helperText={isEmptyCity && `Skills required`}
                      />
                      {isDuplicateCCity && (
                        <span
                          style={{
                            fontSize: "11px",
                            color: "red",
                          }}
                        >
                          {`"${customCityele}"`} already exist
                        </span>
                      )}
                      {isEmptyCustomCity && (
                        <span
                          style={{
                            fontSize: "11px",
                            color: "red",
                          }}
                        >
                          City is Required
                        </span>
                      )}
                    </Box>
                  </Box>
                )}
                 */}

                {/* /*job description */}
                <Box>
                  <Stack
                    direction={"row"}
                    gap={"5px"}
                    sx={{
                      mt: "5px",
                      float: "right",
                      mb: "-30px",
                    }}
                  >
                    <FormControl sx={{ width: "80px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Words
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={jdWords}
                        defaultValue={jdWords}
                        label="Words"
                        onChange={(e) => setJdWords(e.target.value)}
                        size="small"
                        sx={{ height: "30px" }}
                      >
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={150}>150</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                        <MenuItem value={250}>250</MenuItem>
                        <MenuItem value={300}>300</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      onClick={handleGenerateJobDescription}
                      size="small"
                      sx={{
                        float: "right",
                        color: "#fff",
                        backgroundColor: "primary.main",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "primary.main",
                        },
                      }}
                    >
                      Generate Job Description
                    </Button>
                  </Stack>
                </Box>
                {dataATS?.jobDescription ? (
                  <Box sx={{ width: "100%", overflow: "auto" }}>
                    <FormControl>
                      <FormLabel sx={{ fontSize: "14px" }}>
                        Job Description
                      </FormLabel>
                    </FormControl>
                    {console.log("add wla jd", desc)}
                    <CkEditorComponent
                      ckEditorData={desc}
                      handleCkEditorChange={handleCkEditorChange}
                      disabled={dataATS?.jobDescription ? true : false}
                    />
                    {isEmptyDesc && (
                      <span style={{ color: "red" }}>Job Desc Required</span>
                    )}
                  </Box>
                ) : (
                  <Box sx={{ width: "100%", overflow: "auto" }}>
                    <FormControl>
                      <FormLabel sx={{ fontSize: "14px" }}>
                        Job Description
                      </FormLabel>
                    </FormControl>
                    <CkEditorComponent
                      ckEditorData={desc}
                      handleCkEditorChange={handleCkEditorChange}
                    />
                    {generateJobDescriptionError && (
                      <span style={{ color: "red" }}>
                        To generate Job description fill Role, Skills, Years of
                        Experience, and Location input.{" "}
                      </span>
                    )}
                  </Box>
                )}

                {/* old code fro jd */}
                <Box>
                  {/* <TextField
                    multiline
                    maxRows={8}
                    sx={{ width: "100%" }}
                    label="Job Description"
                    variant="outlined"
                    {...register("desc")}
                    error={isEmptyDesc}
                    helperText={errors.desc?.message}
                    onKeyUp={deschandler}
                    inputProps={{ readOnly: dataATS && desc ? true : false }}
                    InputLabelProps={{ shrink: true }}
                  /> */}
                  {/* <Typography>{desc}</Typography> */}
                  {/* <Stack
                    direction={"row"}
                    gap={"5px"}
                    sx={{ mt: "10px", float: "right" }}
                  >
                    <FormControl sx={{ width: "80px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Words
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={jdWords}
                        defaultValue={jdWords}
                        label="Words"
                        onChange={(e) => setJdWords(e.target.value)}
                        size="small"
                        sx={{ height: "30px" }}
                      >
                        <MenuItem value={150}>150</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                        <MenuItem value={250}>250</MenuItem>
                        <MenuItem value={300}>300</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      onClick={handleGenerateJobDescription}
                      size="small"
                      sx={{
                        float: "right",
                        color: "#fff",
                        backgroundColor: "primary.main",
                      }}
                    >
                      Generate Job Description
                    </Button>
                  </Stack> */}
                  {/* {generateJobDescriptionError && (
                    <span style={{ color: "red" }}>
                      To generate Job description fill Role, Skills, Years of
                      Experience, and Location input.{" "}
                    </span>
                  )} */}
                </Box>

                {/* UPLOAD FILE  */}
                <>
                  {/*
                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "fitContent",
                      border: `${
                        isEmptyImageFile ||
                        validImgsize == 0 ||
                        validImgtype == 0
                          ? "1px solid red"
                          : ""
                      }`,
                    }}
                  >       <FileUpload
                  // title="Drag 'n' drop image, or click to select image"
                  sx={{
                    height: "160px",
                    color: "red",
                    // border: `${
                    //   isEmptyImageFile ? "1px solid red" : "1px solid gray"
                    // }`,
                    spacing: "2px",
                  }}
                      title={
                        validImgtype == -1 && validImgsize == -1 ? (
                          <span>
                            "Drag 'n' drop image, or click to select image.
                            <br></br>
                            Image size should not exceed 2MB and must be in .jpg
                            or .jpeg or .png format."
                          </span>
                        ) : validImgtype === 0 ? (
                          <span
                            style={{
                              fontWeight: "bold",
                              textDecoration: "blink",
                            }}
                          >
                            Please upload image in .jpg, .jpeg, or .png format.
                          </span>
                        ) : validImgsize == 0 ? (
                          <span
                            style={{
                              fontWeight: "bold",
                              textDecoration: "blink",
                            }}
                          >
                            File size exceeded.
                          </span>
                        ) : (
                          <span>
                            "Drag 'n' drop image, or click to select image.
                            <br></br>
                            Image size should not exceed 2MB and must be in .jpg
                            or .jpeg or .png format."
                          </span>
                        )
                      }
                      value={image}
                      accept={"image/*,.jpg,.jpeg,.png"}
                      onChange={handleFileUploaderOnChange}
                    />
                  </Box>
                      <Box
                        sx={{
                          border: isEmptyImageFile ? "1px solid red" : "",
                          borderRadius: "4px",
                        }}
                      > <FileUpload
                          sx={{
                            height: "160px",
                            color: "red",
                            // border: `${
                            //   isEmptyImageFile ? "1px solid red" : "1px solid gray"
                            // }`,
                            spacing: "2px",
                          }}
                          title="Drag 'n' drop image, or click to select image"
                          value={image}
                          accept={"image/*"}
                          onChange={handleFileUploaderOnChange}
                      <Button
                        size="small"
                        sx={{
                          float: "right",
                          color: "#fff",
                          backgroundColor: "primary.main",
                          mt: "5px",
                          "&:hover": {
                            backgroundColor: "primary.dark",
                          },
                        }}
                        color="secondary"
                        backgroundColor="primary"
                        onClick={generateJDImg}
                      >
                        Generate Image
                      </Button>
                      {isEmptyImageFile && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "13px",
                            float: "left",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          Image is required
                        </p>
                      )}
                      {generateJDImgErr && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          To generate Image job description is mandatory.
                        </span>
                      )}
                    </Box>
                    {image.length > 0 && (
                      <Box
                        sx={{
                          width: "100%",
                          height: "205px",
                          borderRadius: "5px",
                          overflow: "hidden",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          borderColor: "primary.main",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          src={URL.createObjectURL(image[0])}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>*/}
                </>
                {/* ////////////////////////////////// */}
                <Box sx={{ mt: "-20px" }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "250px",
                      marginBottom: "13px",
                      display: "flex",
                      gap: "10px",
                      // border: "2px solid green",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "fitContent",
                        // border: `${isEmptyImageFile ? "1px solid red" : ""}`,
                      }}
                    >
                      <Button
                        size="small"
                        sx={{
                          float: "right",
                          color: "#fff",
                          backgroundColor: "primary.main",
                          mt: "5px",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "primary.dark",
                          },
                        }}
                        color="secondary"
                        backgroundColor="primary"
                        onClick={generateJDImg}
                      >
                        Generate Image
                      </Button>
                      <Box
                        // sx={{
                        //   border: isEmptyImageFile ? "1px solid red" : "",
                        //   borderRadius: "4px",
                        // }}
                        sx={{
                          width: "100%",
                          height: "fitContent",
                          marginTop: "40px",
                          border: `${
                            isEmptyImageFile ||
                            validImgsize == 0 ||
                            validImgtype == 0
                              ? "1px solid red"
                              : ""
                          }`,
                        }}
                      >
                        <FileUpload
                          sx={{
                            height: "160px",
                            color: "red",
                            spacing: "2px",
                          }}
                          title={
                            validImgtype == -1 && validImgsize == -1 ? (
                              <span>
                                "Drag 'n' drop image, or click to select image.
                                <br></br>
                                Image size should not exceed 2MB and must be in
                                .jpg or .jpeg or .png format."
                              </span>
                            ) : validImgtype === 0 ? (
                              <span
                                style={{
                                  fontWeight: "bold",
                                  textDecoration: "blink",
                                }}
                              >
                                Please upload image in .jpg, .jpeg, or .png
                                format.
                              </span>
                            ) : validImgsize == 0 ? (
                              <span
                                style={{
                                  fontWeight: "bold",
                                  textDecoration: "blink",
                                }}
                              >
                                File size exceeded.
                              </span>
                            ) : image.length == 0 ? (
                              <span>
                                "Drag 'n' drop image, or click to select image.
                                <br></br>
                                Image size should not exceed 2MB and must be in
                                .jpg or .jpeg or .png format."
                              </span>
                            ) : (
                              " "
                            )
                          }
                          //title="Drag 'n' drop image, or click to select image"
                          value={image}
                          accept={"image/*,.jpg,.jpeg,.png"}
                          onChange={handleFileUploaderOnChange}
                        />
                      </Box>

                      {isEmptyImageFile && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "13px",
                            float: "left",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          Image is required
                        </p>
                      )}
                      {generateJDImgErr && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          To generate Image job description is mandatory.
                        </span>
                      )}
                    </Box>
                    {image.length > 0 && (
                      <Box
                        sx={{
                          width: "100%",
                          height: "205px",
                          borderRadius: "5px",
                          overflow: "hidden",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          borderColor: "primary.main",
                          marginTop: "40px",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          src={URL.createObjectURL(image[0])}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
                {/* <Box sx={{ width: "100%", overflow: "auto" }}>
                  <FormControl>
                    <FormLabel sx={{ fontSize: "14px" }}>
                      Job Description
                    </FormLabel>
                  </FormControl>
                  <CkEditorComponent
                    ckEditorData={desc}
                    handleCkEditorChange={handleCkEditorChange}
                  />
                </Box>
                <>
                  <textarea
                    rows={10}
                    cols={100}
                    label="Job Description*"
                    style={{
                      width: "99%",
                      border: activeColor,
                    }}
                    variant="outlined"
                    placeholder="Job Description"
                    {...register("desc", {})}
                    error={isEmptyDesc}
                    helperText={errors.desc?.message}
                    onKeyUp={deschandler}
                  />
                </> */}

                {/* currency okay */}
                <Box>
                  <Autocomplete
                    id="combo-box-demo"
                    options={countryData}
                    sx={{
                      width: "100%",
                      marginTop: "-15px",
                    }}
                    // value={indianCurrency}
                    defaultValue={indianCurrency}
                    getOptionLabel={(option) =>
                      option.currency_symbol +
                      " " +
                      ":" +
                      " " +
                      option.currency_name +
                      " " +
                      "[" +
                      option.currency +
                      "]" +
                      " " +
                      "[" +
                      option.name +
                      "]"
                    }
                    //  onChange={(event, value) => {
                    //   setCurrencyId(
                    //     value?.id ? value.id : ""
                    //   );
                    // }}
                    onChange={currencyOnChange}
                    renderInput={(params, idx) => {
                      // console.log("Params: ", params);
                      return (
                        <TextField
                          {...params}
                          label={<ReuseableLabel label={"Currency"} />}
                          error={isEmptyCurrency}
                          helperText={isEmptyCurrency && `Currency is required`}
                        />
                      );
                    }}
                  />
                </Box>
                <Box>
                  <Stack
                    direction={"row"}
                    justifyContent={"right"}
                    spacing={2}
                    sx={{ width: "100%" }}
                  >
                    <Button
                      sx={{
                        borderRadius: "6px",
                        // backgroundColor: "#1E0D61",
                        backgroundColor: "primary.main",
                        color: "#fff",
                        textTransform: "none",
                        "&:hover": {
                          // background: "#220986" ,
                          backgroundColor: "primary.dark",
                        },
                      }}
                      color="secondary"
                      backgroundColor="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={handleClose}
                      sx={{
                        borderRadius: "6px",
                        // background: "#FE9A25",
                        backgroundColor: "secondary.main",
                        color: "#fff",
                        textTransform: "none",
                        "&:hover": {
                          //  background: "#e9983c"
                          backgroundColor: "secondary.dark",
                        },
                      }}
                      color="secondary"
                      // backgroundColor="red"
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </form>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default JobTabDashboard;
