import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  Box,
  IconButton,
  Menu,
  Modal,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  Close,
  DeleteOutline,
  EditOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { makeStyles, useTheme } from "@mui/styles";
import axios from "axios";
import DeleteAlert from "../AlertBox/DeleteAlert";
import SearchFeild from "./SearchFeild";
import EditJobRoleModal from "./EditJobRoleModal";
import {
  getJobRoleData,
  editJobRoleData,
  deleteJobRoles,
  editJobroleStatus,
} from "../../Redux/JobRole/action";
import { initailState } from "../../Pages/JobTabDashboard";
import { environmentDetails } from "../../../src/environment/environment";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { IOSSwitchButton } from "../ReusableComponents/SwitchButtons";
import ApiAlert from "../AlertBox/ApiAlert";
import { editCompanyStatus } from "../../Redux/HiringCompany/action";
import Tooltip from "@mui/material/Tooltip";
import { privateAxiosInstance } from "../../utils/axiosInstance";
import { accessTokenHeadersObject } from "../../utils/headers";
import IButtonModal from "../Modals/IButtonModal";
import img_not_found from "../../assets/img_not_found.jpg";
import { roleModulePermissions } from "../../utils/roleInstance";

const apiUrl = environmentDetails.apiUrl;

const columns = [
  { id: "SNo", label: "Sl. #", minWidth: 30, align: "right" },
  { id: "JobRole", label: "Job Role", minWidth: 150, align: "left" },
  { id: "Skills", label: "Primary Skills", minWidth: 130, align: "left" },
  { id: "country", label: "Country", minWidth: 100, align: "left" },
  { id: "cities", label: "Cities", minWidth: 100, align: "left" },
  {
    id: "YearsOfExperience",
    label: "Years of Experience",
    minWidth: 130,
    align: "left",
  },
  {
    id: "JobDescription",
    label: "Job Description",
    minWidth: 100,
    align: "left",
  },
  { id: "ImageLink", label: "", miWidth: 110, align: "center" },
  { id: "Action", label: "Action", minWidth: 80, align: "center" },
];

const useStyles = makeStyles((theme) => ({
  editIconButton: {
    "&:hover": {
      color: "rgb(254, 154, 37)", // Set EditIcon hover text color here
    },
  },
  deleteIconButton: {
    "&:hover": {
      color: "red", // Set deleteIconButton hover text color here
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      // borderRight: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid gray`,
      borderBottom: "none",
      padding: 5,
      paddingTop: 8,
      paddingBottom: 8,
      cursor: "pointer",
      minWidth: 25,
      //this works but only for cell hover, not the full row
      "&:hover": {
        backgroundColor: "red",
      },
    },
  },
}));

const JobRoleTable = ({
  jobRoleData,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  getJobRoleDataFn,
}) => {
  const rows = jobRoleData?.data || [];
  const classes = useStyles();
  const dispatch = useDispatch();
  const [image, setImage] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [editData, setEditData] = useState({});
  //const [idToDelete, setIdToDelete] = useState(null);
  const [switchInactive, setSwitchInactive] = useState(null);
  const [copyStatus, setCopyStatus] = useState(Boolean);
  const [jobIdCopyStatus, setJobIdCopyStatus] = useState(0);
  let isLengthChange = jobRoleData?.length !== 0 ? true : false;

  const [alert, setAlert] = useState(false);
  const [alertContent, setalertcontent] = useState("");
  const [alertStatus, setAlertStatus] = useState(Boolean);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [showDesc, setShowDesc] = useState("");
  const [showImage, setShowImage] = useState("");
  const [openingsWithJobId, setOpeningsWithJobId] = useState(0);
  const [showSkill, setShowSkill] = useState("");

  const theme = useTheme();

  const openJobDesc = Boolean(anchorEl);
  const openJobImage = Boolean(anchorEl2);
  const openJobSkill = Boolean(anchorEl3);

  // useEffect(() => {
  //   console.log("jobRoleData inide j role table useeffect:--> ",jobRoleData)
  //   console.log("jobRoleData LENGTH inide j role table useeffect:--> ",jobRoleData?.dataLength)
  //   if (page !== 0) {
  //     setPage(0);
  //   }
  // }, [isLengthChange]);

  ////////////console.log(idToDelete);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    let val = +event.target.value;
    // if(val>=jobRoleData?.dataLength){
    //   setPage(0);
    // }
    setRowsPerPage(val);
    setPage(0);
  };

  const handleClickOpen = (data) => {
    setSwitchInactive(data);
    privateAxiosInstance
      .get(`/questionnaire/get-openingByJobRoleId/${data.jobId}`, {
        headers: { ...accessTokenHeadersObject() },
      })
      .then((res) => {
        setOpen(true);
        setOpeningsWithJobId(
          res.status === 200 ? res.data.openingsWithJobId : 0
        );
      })
      .catch((err) => {
        setOpen(true);
        setOpeningsWithJobId(0);
        //console.log("get opening by job ierrd==> ", err);
      });
  };

  const handleEditJobRole = (jobRole) => {
    // console.log("job roleeeee acha", jobRole);
    // editJobRole(jobRole);
    setEditData(jobRole);
    setOpenEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  ////
  //console.log("edit job role: ", editData);
  //console.log("edit job role: ", editData);

  // const jobRoleDelete = () => {
  //   dispatch(deleteJobRoles(idToDelete))
  //     .then((res) => {
  //       handleClose();
  //       //dispatch(getJobRoleData());
  //       setAlert(true);
  //       setAlertStatus(res.payload.status);
  //       setalertcontent(res.payload.message);
  //       getJobRoleDataFn();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const jobRoleEdit = (jobId, data) => {
    dispatch(editJobRoleData(jobId, data))
      .then((res) => {
        console.log("res from tab", res);
        setOpenEdit(false);
        // dispatch(getJobRoleData());
        setAlert(true);
        console.log("status", res.payload.status);
        setAlertStatus(res.payload.status);
        setalertcontent(res.payload.message);
        getJobRoleDataFn();
        setImage([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeSwitch = (data) => {
    // console.log(data);
    var statusChange = true;
    const jobRoleStatus = data.jobRoleStatus;
    if (jobRoleStatus === true) {
      statusChange = false;
    } else if (jobRoleStatus === false) {
      statusChange = true;
    }
    // console.log("statusChange", statusChange);
    dispatch(editJobroleStatus(data.jobId, statusChange))
      .then((res) => {
        if (res.type === "EDIT_JOBROLE_STATUS_SUCCESS") {
          setAlert(true);
          setAlertStatus(res.payload.status);
          setalertcontent(res.payload.message);
        } else {
          setAlert(true);
          setAlertStatus(res.payload.status);
          setalertcontent(res.payload.message);
        }
        dispatch(getJobRoleData());
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCopyImage = (imageLink, jobId) => {
    if (navigator.clipboard.writeText(imageLink)) {
      setCopyStatus(true);
      setJobIdCopyStatus(jobId);
    } else {
      setCopyStatus(false);
    }
  };

  const AlerthandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert(false);
  };

  // Check Role Permission
  const [jobEditAction, setJobEditAction] = useState("")
  const [jobDeleteAction, setJobDeleteAction] = useState("")

  const checkJobEditAction = async() => { 
    const roleData = {
      roleModule : 'Job Role'
    }
    let rolePermission = await roleModulePermissions.checkUserRoles(roleData,'edit')
    // return rolePermission
    setJobEditAction(rolePermission)
  }

  const checkJobDeleteAction = async() => { 
    const roleData = {
      roleModule : 'Job Role'
    }
    let rolePermission = await roleModulePermissions.checkUserRoles(roleData,'delete')
    // return rolePermission
    setJobDeleteAction(rolePermission)
  }

  useEffect(() => {
    checkJobEditAction()
    checkJobDeleteAction()
  },[page])
  // Check Role Permission

  return (
    <>
      <Box sx={{ width: "100%", backgroundColor: "blue" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "calc(100vh - 170px)" }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    return (
                      <TableCell
                        sx={{
                          // backgroundColor: "rgb(30, 13, 97)",
                          // color: "rgb(254, 154, 37)",
                          backgroundColor: "primary.main",
                          color: "secondary.main",
                          minWidth: column.minWidth,
                        }}
                        key={column.id}
                        align={column.align}
                      >
                        {column.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor:
                        row.jobRoleStatus === false ? "#dddddd" : "block",
                    }}
                  >
                    <TableCell component="th" scope="row" align="right">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell align="left">{row?.role}</TableCell>
                    {/* <TableCell align="left">{row?.skill?.join(", ")}</TableCell> */}

                    <TableCell align="left">
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        spacing={"0"}
                      >
                        {row?.skill === ""
                          ? "N/A"
                          : row?.skill.join(", ").slice(0, 15) + "..."}
                        {row?.skill ? (
                          <Tooltip title={"Click to view all skills."} arrow>
                            <IconButton
                              onClick={(event) => {
                                setAnchorEl3(event.currentTarget);
                                setShowSkill(row?.skill.join(", "));
                              }}
                            >
                              <InfoOutlined sx={{ fontSize: "15px" }} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                        <IButtonModal
                          type={"text"}
                          element={showSkill}
                          anchorEl={anchorEl3}
                          setAnchorEl={setAnchorEl3}
                        />
                        {/* <Menu
                          id="account-menu"
                          open={anchorEl3 ? true : false}
                          onClose={() => {
                            setAnchorEl3(null);
                          }}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              width: "400px",
                              height: "auto",
                              maxHeight: "500px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              padding: "25px",
                              filter: theme.shadows.filterShadow,
                            },
                          }}
                          transformOrigin={{
                            horizontal: "center",
                            vertical: "center",
                          }}
                          anchorOrigin={{
                            horizontal: "center",
                            vertical: "center",
                          }}
                        >
                          <Box sx={{ position: "relative" }}>
                            <IconButton
                              size="small"
                              onClick={() => setAnchorEl3(null)}
                              sx={{
                                position: "absolute",
                                top: "-50px",
                                right: "-40px",
                                backgroundColor: "primary.light",
                              }}
                            >
                              <Close />
                            </IconButton>
                            <Box
                              sx={{
                                width: "400px",
                                height: "500px",
                                overflowY: "auto",
                                borderRadius: "5px",
                              }}
                            >
                              <Typography>{showSkill}</Typography>
                            </Box>
                          </Box>
                        </Menu> */}
                      </Stack>
                    </TableCell>

                    <TableCell align="left">{row?.country}</TableCell>
                    <TableCell align="left">
                      {row?.cities?.join(", ")}
                    </TableCell>
                    <TableCell align="left">
                      {row?.yearsOfExperience === ""
                        ? "N/A"
                        : row?.yearsOfExperience}
                    </TableCell>
                    <TableCell align="left">
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        spacing={"0"}
                      >
                        {row?.desc === ""
                          ? "N/A"
                          : row?.desc
                              ?.replace(/<[^>]*>|[\r\n]/g, "")
                              .slice(0, 15) + "..."}
                        {row?.desc ? (
                          <Tooltip
                            title={"Click to view full description."}
                            arrow
                          >
                            <IconButton
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setShowDesc(
                                  row?.desc.replace(/<[^>]*>|[\r\n]/g, "")
                                );
                              }}
                            >
                              <InfoOutlined sx={{ fontSize: "15px" }} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                        <IButtonModal
                          type={"text"}
                          element={showDesc}
                          anchorEl={anchorEl}
                          setAnchorEl={setAnchorEl}
                        />
                        {/* <Menu
                          // anchorEl={anchorEl}
                          id="account-menu"
                          // open={openJobDesc}
                          open={anchorEl ? true : false}
                          onClose={() => {
                            setAnchorEl(null);
                          }}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              width: "400px",
                              height: "auto",
                              maxHeight: "500px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              padding: "25px",
                              // filter: "drop-shadow(0px 2px 3px rgba(255, 255, 255, 0.32))"
                              //filter: "drop-shadow(0px 2px 3px rgba(170, 170, 170, 0.22))"
                              filter: theme.shadows.filterShadow,
                            },
                          }}
                          transformOrigin={{
                            horizontal: "center",
                            vertical: "center",
                          }}
                          anchorOrigin={{
                            horizontal: "center",
                            vertical: "center",
                          }}
                        >
                          <Box sx={{ position: "relative" }}>
                            <IconButton
                              size="small"
                              onClick={() => setAnchorEl(null)}
                              sx={{
                                position: "absolute",
                                top: "-50px",
                                right: "-40px",
                                //fontSize: "18px",
                                backgroundColor: "primary.light",
                              }}
                            >
                              <Close />
                            </IconButton>
                            <Box
                              sx={{
                                width: "400px",
                                height: "500px",
                                overflowY: "auto",
                                borderRadius: "5px",
                                //paddingX:"25px"
                              }}
                            >
                              <Typography>{showDesc}</Typography>
                            </Box>
                          </Box>
                        </Menu> */}
                      </Stack>
                    </TableCell>
                    <TableCell align="center" style={{ width: "10%" }}>
                      <Stack direction={"row"} gap={4}>
                        {/* <p style={{color: "skyblue", cursor: "pointer",textDecoration: "underline",}}>{row?.image}</p> */}
                        {row?.image ? (
                          <Tooltip
                            title="Click to view image"
                            arrow
                            placement="left"
                          >
                            <img
                              src={row?.image}
                              style={{
                                width: "65%",
                                border: "2px solid #fe9a25",
                                borderRadius: "8px",
                                cursor: "pointer",
                              }}
                              onError={(event) => {
                                event.target.onerror = null;
                                event.target.src = img_not_found;
                              }}
                              onClick={(event) => {
                                setAnchorEl2(event.currentTarget);
                                setShowImage(row?.image);
                              }}
                            ></img>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                        <IButtonModal
                          type={"img"}
                          element={showImage}
                          anchorEl={anchorEl2}
                          setAnchorEl={setAnchorEl2}
                        />
                        {/* <Menu
                          //anchorEl={anchorEl2}
                          id="account-menu"
                          //open={openJobImage}
                          open={anchorEl2 ? true : false}
                          onClose={() => {
                            setAnchorEl2(null);
                          }}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              width: "400px",
                              height: "auto",
                              maxHeight: "500px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              padding: "25px",
                              //filter:"drop-shadow(0px 2px 3px rgba(0,0,0,0.32))",
                              filter: theme.shadows.filterShadow,
                            },
                          }}
                          transformOrigin={{
                            horizontal: "center",
                            vertical: "center",
                          }}
                          anchorOrigin={{
                            horizontal: "center",
                            vertical: "center",
                          }}
                        >
                          <Box sx={{ position: "relative" }}>
                            <IconButton
                              size="small"
                              onClick={() => setAnchorEl2(null)}
                              sx={{
                                position: "absolute",
                                top: "-50px",
                                right: "-40px",
                                //fontSize: "18px",
                                backgroundColor: "primary.light",
                              }}
                            >
                              <Close />
                            </IconButton>
                            <Box
                              sx={{
                                width: "400px",
                                height: "500px",
                                overflowY: "auto",
                                borderRadius: "5px",
                                //paddingX:"25px"
                              }}
                            >
                              <Typography>
                                <img
                                  width="400"
                                  src={showImage}
                                  onError={(event) => {
                                    event.target.onerror = null;
                                    event.target.src = img_not_found;
                                  }}
                                />
                              </Typography>
                            </Box>
                          </Box>
                        </Menu> */}

                        {/* <Box style={{ marginTop: "15px" }}>
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleCopyImage(row?.image, row?.jobId)
                            }
                          >
                            {copyStatus && row.jobId === jobIdCopyStatus ? (
                              <FileDownloadDoneIcon
                                style={{ color: "#1e0d61" }}
                              />
                            ) : (
                              <Tooltip
                                title="Copy Image Link"
                                arrow
                                placement="top"
                              >
                                <ContentCopyRoundedIcon
                                  style={{ color: "#fe9a25" }}
                                />
                              </Tooltip>
                            )}
                          </p>
                        </Box> */}
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ cursor: "pointer", position: "sticky", right: 0 }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                      >
                        <p
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleCopyImage(row?.image, row?.jobId)
                          }
                        >
                          {copyStatus && row.jobId === jobIdCopyStatus ? (
                            <FileDownloadDoneIcon
                              style={{ color: "#1e0d61" }}
                            />
                          ) : (
                            <Tooltip
                              title="Copy Image Link"
                              arrow
                              placement="top"
                            >
                              <ContentCopyRoundedIcon
                                style={{ color: "#fe9a25" }}
                              />
                            </Tooltip>
                          )}
                        </p>

                        {jobEditAction != '' && jobEditAction != undefined ?
                          <Tooltip title="Edit job role" arrow placement="top">
                            <EditOutlined
                              fontSize="medium"
                              className={classes.editIconButton}
                              onClick={() => handleEditJobRole(row)}
                              sx={{
                                display:
                                  row.jobRoleStatus === true ? "block" : "none",
                              }}
                            />
                          </Tooltip>
                        : ''}

                        {/*<DeleteOutline
                          className={classes.deleteIconButton}
                          fontSize="medium"
                          style={{ marginLeft: 8 }}
                          onClick={() => handleClickOpen(row.jobId)}
                          sx={{ display: row.jobRoleStatus === true ? 'block' : 'none' }}
                        />
                        />*/}

                        {jobDeleteAction != '' && jobDeleteAction != undefined ?
                          <Tooltip
                            title={row.jobRoleStatus ? "Disable" : "Enable"}
                            arrow
                            placement="top"
                          >
                            <IOSSwitchButton
                              size="small"
                              // onClick={() => handleChangeSwitch(row)}
                              onClick={
                                row.jobRoleStatus === false
                                  ? () => {
                                      handleChangeSwitch(row);
                                    }
                                  : () => {
                                      handleClickOpen(row);
                                    }
                              }
                              checked={row.jobRoleStatus === true}
                            />
                          </Tooltip>
                        : ''}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={jobRoleData?.dataLength || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <DeleteAlert
          open={open}
          onClose={handleClose}
          handleClose={handleClose}
          handleDelete={() => handleChangeSwitch(switchInactive)}
          rightButton={"Inactive"}
          haeader={"Inactive"}
          message={
            openingsWithJobId
              ? `${
                  openingsWithJobId > 1 ? "All" : ""
                } ${openingsWithJobId} openings with this job Role will inactive, are you still want to make this job role inactive`
              : "Are you sure you want to make  this job role inactive?"
          }
        />

        {openEdit && (
          <EditJobRoleModal
            open={openEdit}
            setOpen={setOpenEdit}
            data={editData}
            jobRoleEdit={jobRoleEdit}
            image={image}
            setImage={setImage}
          />
        )}
        {alert && (
          <ApiAlert
            open={alert}
            snackbaronClose={AlerthandleClose}
            alertonClose={AlerthandleClose}
            alertType={alertStatus ? "success" : "error"}
            Content={alertContent}
          />
        )}
      </Box>
    </>
  );
};

export default JobRoleTable;
